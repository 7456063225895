<template>
  <div class="page-content">
    <div class="container">
      <ul
        class="nav nav-pills mb-3 tab-style-1 justify-content-center"
        id="pills-tab"
        role="tablist"
      >
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            type="button"
            :class="{ active: currentTab === 'ORDER' }"
            @click="onChangeTab('ORDER')"
          >
            Order
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            type="button"
            :class="{ active: currentTab === 'QUOTATION' }"
            @click="onChangeTab('QUOTATION')"
          >
            Quotation
          </button>
        </li>
      </ul>
      <div v-if="currentTab == 'ORDER'">
        <div class="row mb-4">
          <div class="col-lg-8">
            <a
              v-on:click="this.$router.push('/home')"
              class="float-start mt-1 me-4 cursor-pointer"
            >
              <svg
                width="12"
                height="16"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                  stroke="#001D21"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
            <h1 class="page-title ms-4">Order</h1>
          </div>
          <div class="col-lg-4">
            <div class="input-group page-search-control">
              <span
                @click="getOrderList(currentPage)"
                class="input-group-text cursor-pointer"
                id="inputGroup-sizing-default"
                ><img src="static/images/search-icon-dark.svg" width="14" class="icon"
              /></span>
              <input
                type="text"
                class="form-control"
                placeholder="Search by vehicle or workshop"
                v-model="keyword"
                @keydown.enter="getOrderList(currentPage)"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 mb-4" v-for="(item, index) in orderList" :key="index">
            <div class="card order-card cursor-pointer" style="height: 100%">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-lg-6">
                    <div class="font-14 mb-1">
                      {{ $helperService.dateFormateForOrder(item.created_at) }}
                    </div>
                    <div class="font-14 text-secondary">
                      Order ID:
                      {{ item && item.order_no ? item.order_no : "-" }}
                    </div>
                  </div>
                  <div class="col-lg-6 text-end">
                    <span
                      v-if="
                        item.order_status == 'PENDING' ||
                        item.order_status == 'RESHEDULE_BY_CUSTOMER' ||
                        item.order_status == 'IN_PROGRESS' ||
                        item.order_status == 'REVICE_COST' ||
                        item.order_status == 'ESTIMATE_ACCEPTED' ||
                        item.order_status == 'COST_ACCEPTED' ||
                        (item.is_revice_proforma == 'Y' &&
                          item.order_status == 'REVICE_ESTIMATE')
                      "
                      class="badge bg-primary font-14 font-medium action-rq-badge"
                      >Action Required</span
                    >
                    <span
                      class="badge bg-warning font-14 font-medium"
                      v-if="
                        item?.order_status === 'PENDING' ||
                        item?.order_status === 'IN_PROGRESS'
                      "
                    >
                      {{
                        item?.order_status === "PENDING"
                          ? "Order Received"
                          : $helperService.getOrder(item.order_status)
                      }}</span
                    >
                    <span
                      class="badge bg-danger font-14 font-medium"
                      v-else-if="
                        item?.order_status === 'CANCELLED' ||
                        item?.order_status === 'AUTO_CANCELLED'
                      "
                    >
                      {{ $helperService.getOrder(item.order_status) }}</span
                    >
                    <span
                      class="badge bg-info font-14 font-medium"
                      v-else-if="
                        item?.order_status === 'RESCHEDULED_BY_VENDOR' ||
                        item?.order_status === 'RESCHEDULED_BY_CUSTOMER'
                      "
                    >
                      Rescheduled</span
                    >
                    <span
                      class="badge bg-success font-14 font-medium"
                      v-else-if="
                        item?.order_status === 'DELIVERED' ||
                        item?.order_status === 'COMPLETED'
                      "
                    >
                      {{ $helperService.getOrder(item.order_status) }}</span
                    >
                    <span class="badge bg-info font-14 font-medium" v-else>
                      {{ $helperService.getOrder(item.order_status) }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="card-body" v-on:click="moveToOrderDetails(item.uuid)">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="border p-2 rounded text-center mb-2">
                      <img
                        class="img-fluid mb-2"
                        style="width: 90px"
                        :src="
                          item?.uservehicle?.manufacturer_model?.icon ||
                          '/static/images/default.png'
                        "
                        alt=""
                      />
                      <p class="mb-0">
                        {{ item?.uservehicle?.manufacturer_model?.title || "" }}
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div
                      v-if="item?.uservehicle?.vehicle_registration_number"
                      class="p-2 bg-secondary-light d-inline-block rounded px-3 mb-2"
                    >
                      {{ item?.uservehicle?.vehicle_registration_number }}
                    </div>
                    <div class="mb-2">
                      <span class="text-secondary">Fuel type:</span>
                      <span class="ms-2">{{
                        item?.uservehicle?.fuel?.title || "-"
                      }}</span>
                    </div>
                    <div class="mb-2">
                      <span class="text-secondary">Transmission type:</span>
                      <span class="ms-2">{{
                        item?.uservehicle?.transmission?.title || "-"
                      }}</span>
                    </div>
                  </div>
                  <div class="col-12 d-flex gap-3 mb-3">
                    <div class="mb-1">
                      <span class="text-secondary">Services:</span>
                    </div>
                    <div v-if="item && item.categories && item.categories.length > 0">
                      <p class="text mb-0">
                        {{
                          item.categories.map((obj) => obj?.category?.title).join(", ")
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <table class="table table-borderless mb-0">
                      <tr v-if="item?.is_pickup === 'Y'">
                        <td class="text-secondary">Pick-up</td>
                        <td
                          v-if="item?.workshop?.pickup_drop?.is_pickup === 'Y'"
                          class="font-medium"
                        >
                          <span v-if="item.workshop.pickup_drop.pickup_cost > 0"
                            >₹{{ item.workshop.pickup_drop.pickup_cost }}</span
                          >
                          <span v-else>Free</span>
                        </td>
                        <td class="font-medium" v-else>NA</td>
                      </tr>
                      <tr v-if="item?.is_drop === 'Y'">
                        <td class="text-secondary" style="padding-top: 10px">Drop</td>
                        <td
                          v-if="item?.workshop?.pickup_drop?.is_drop === 'Y'"
                          class="font-medium"
                          style="padding-top: 10px"
                        >
                          <span v-if="item.workshop.pickup_drop.drop_cost > 0"
                            >₹{{ item.workshop.pickup_drop.drop_cost }}</span
                          >
                          <span v-else>Free</span>
                        </td>
                        <td class="font-medium" v-else>NA</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-lg-6 px-0">
                    <table class="table table-borderless mb-0 font-14">
                      <tr>
                        <td class="text-secondary">Booking date</td>
                        <td class="font-medium">
                          {{ $helperService.dateFormat(item.pickup_date) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-secondary" style="padding-top: 16px">
                          Booking slot
                        </td>
                        <td class="font-medium" style="padding-top: 16px">
                          {{ item && item.pickup_time ? item.pickup_time : "-" }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-footer d-flex gap-3">
                <button
                  v-if="
                    item?.order_status === 'PENDING' ||
                    item?.order_status === 'RESCHEDULED_BY_CUSTOMER'
                  "
                  class="btn btn-primary w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#AcceptModal"
                  v-on:click="storeUUID(item.uuid)"
                >
                  Accept
                </button>
                <button
                  v-if="
                    item?.order_status === 'PENDING' ||
                    item?.order_status === 'RESCHEDULED_BY_CUSTOMER'
                  "
                  class="btn btn-outline-danger bg-danger-subtle rounded-3 w-100"
                  style="padding: 10px 20px"
                  data-bs-toggle="modal"
                  data-bs-target="#CancelModal"
                  v-on:click="storeUUID(item?.uuid)"
                >
                  Decline
                </button>

                <button
                  v-if="
                    item?.order_status === 'PENDING'
                  "
                  class="btn btn-outline-primary w-100"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#RescheduleBlade"
                  v-on:click="
                    openRescheduleModel(item.uuid, item.pickup_date, item.pickup_time)
                  "
                >
                  Reschedule
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: end">
          <Pagination
            @page-change="pageChange"
            @items-per-page-change="itemsPerPageChange"
            ref="listPagination"
          />
        </div>
      </div>
      <ErrorComponent @retry="getOrderList(currentPage)" ref="errorComponent" />
      <div v-if="currentTab == 'QUOTATION'">
        <Quotation />
      </div>
    </div>
  </div>

  <!----------------------------- Reschedule Modal  ----------------------------------->

  <div
    class="offcanvas offcanvas-end size-md"
    tabindex="-1"
    id="RescheduleBlade"
    aria-labelledby="RescheduleBladeLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="RescheduleBladeLabel">Propose Time</h5>
      <button
        id="close-model-popup"
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body reshcedule-btns">
      <div class="d-flex justify-content-between mb-3">
        <span class="font-bold font-18">Select Date</span>
      </div>
      <div
        class="btn-group check-btns flex-wrap gap-3 d-grid"
        style="grid-template-columns: auto auto auto"
        role="group"
      >
        <div v-for="(item, index) in rescheduleDateList" :key="index" class="btn-group">
          <input
            type="checkbox"
            class="btn-check"
            :id="'day_' + index"
            autocomplete="off"
            :disabled="item.is_off == 'Y'"
            v-on:change="storeDate(item.date, $event)"
            :checked="isCheckedDate(item.date)"
          />
          <label class="btn btn-outline-primary" :for="'day_' + index">
            {{ $helperService.dateFormat(item?.date) }}
          </label>
        </div>
      </div>
      <hr />
      <div class="d-flex justify-content-between mb-3">
        <span class="font-bold font-18">Select time slot</span>
      </div>
      <div
        class="btn-group check-btns flex-wrap gap-3 d-grid"
        style="grid-template-columns: auto auto auto"
        role="group"
      >
        <div v-for="(item, index) in rescheduleTimeList" :key="index" class="btn-group">
          <input
            type="checkbox"
            class="btn-check"
            :id="'time' + index"
            autocomplete="off"
            v-on:change="storeTime(item.title, $event)"
            :checked="isCheckedTime(item.title)"
          />
          <label class="btn btn-outline-primary" :for="'time' + index">{{
            item?.title
          }}</label>
        </div>
      </div>
      <ErrorComponent @retry="getDetails()" ref="errorComponent1" />
    </div>
    <div class="offcanvas-footer p-3">
      <button
        class="btn btn-primary w-100"
        data-bs-toggle="modal"
        data-bs-target="#RescheduleModal"
        :disabled="!this.selectedDate && !this.selectedTime"
      >
        Propose New Time
      </button>
    </div>
  </div>

  <!-- Accept Alert -->
  <div
    class="modal fade"
    id="AcceptModal"
    tabindex="-1"
    aria-labelledby="AcceptModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content">
        <div class="modal-body text-center p-4">
          <p class="font-medium font-16 mb-4">Are you sure you want to accept?</p>
          <div class="d-flex gap-3">
            <button
              id="close-accept-popup"
              type="button"
              class="btn btn-outline-danger w-100"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              id="order-accept"
              type="button"
              class="btn btn-primary w-100"
              v-on:click="orderAccept"
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Reschedule Alert -->
  <div
    class="modal fade"
    id="RescheduleModal"
    tabindex="-1"
    aria-labelledby="RescheduleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content">
        <div class="modal-body text-center p-4">
          <p class="font-medium font-16">Propose New Time</p>
          <p class="text-secondary mb-4">Are you sure you want to propose new time?</p>
          <div class="d-flex gap-3">
            <button
              id="close-save-date-time-popup"
              type="button"
              class="btn btn-outline-danger w-100"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              id="save-reschedule-date-time"
              type="button"
              class="btn btn-primary w-100"
              v-on:click="saveRescheduleDateAndTime"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- -------------------------------Cancel Model---------------------------------------- -->
  <div
    class="modal fade"
    id="CancelModal"
    tabindex="-1"
    aria-labelledby="CancelModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <p class="font-bold font-18">Cancel order</p>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="calncel-type"
              id="equestedtocancel"
              v-model="cancelReason"
              value="Customer requested to cancel"
            />
            <label class="form-check-label" for="equestedtocancel">
              Customer requested to cancel
            </label>
          </div>
          <div class="form-check mb-4">
            <input
              class="form-check-input"
              type="radio"
              name="calncel-type"
              id="notResponding"
              v-model="cancelReason"
              value="Customer is not responding to call"
            />
            <label class="form-check-label" for="notResponding">
              Customer is not responding to call
            </label>
          </div>

          <button
            id="cancel-order"
            type="button"
            class="btn btn-primary w-100"
            data-bs-dismiss="modal"
            v-on:click="cancelOrder"
            :disabled="!this.cancelReason"
          >
            Cancel Order
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.action-rq-badge {
  position: absolute;
  left: calc(50% - 63px);
  top: -16px;
}
</style>
<script>
import ErrorComponent from "@/components/ErrorComponent.vue";
import Pagination from "@/components/Pagination.vue";
import Quotation from "@/views/Order/Quotation.vue";

export default {
  name: "YourOrder",
  components: {
    ErrorComponent,
    Pagination,
    Quotation,
  },

  data() {
    return {
      orderList: [],
      workshop_id: localStorage.getItem("workshop_id"),
      page_type: "",
      finalrescheduleTimeList: [],
      rescheduleTimeList: [],
      rescheduleDateList: [],
      uuid: "",
      cancelReason: "",
      selectedDate: "",
      pickup_date: "",
      pickup_time: "",
      selectedTime: "",
      offset: -1,
      currentTab: this.$store.getters.getTabValue
        ? this.$store.getters.getTabValue
        : "ORDER",
      keyword: "",
      currentPage: this.$store.getters.getCurrentPage
        ? this.$store.getters.getCurrentPage
        : 0,
      status: "",
    };
  },

  mounted() {
    this.getDetails();
    localStorage.setItem("page_type", "HOME");
    this.page_type = "HOME";
    var status = this.$sharedService.getMapValue("order_type");
    if (status == "QUOTATION") {
      this.currentTab = "QUOTATION";
    } else {
      if (status) {
        this.status = status;
        this.$sharedService.clearMap("order_type");
      }
      if (this.workshop_id && this.currentTab === "ORDER") {
        this.getOrderList(this.currentPage);
      }
    }

    this.$store.commit("setPageType", "HOME");
  },

  methods: {
    onChangeTab(tab) {
      this.currentPage = 0;
      this.$store.commit("setCurrentPage", this.currentPage);
      this.$store.commit("setTabValue", tab);
      this.keyword = "";
      this.$refs.errorComponent.isShowMessageValue(false);
      this.currentTab = tab;
      if (this.currentTab === "ORDER") {
        this.getOrderList(this.currentPage);
      }
    },
    getOrderList(page) {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.orderList = [];
      this.$api
        .getAPI({
          _action: "/order/list/" + this.workshop_id,
          _body: { offset: page, keyword: this.keyword, status: this.status },
          _hide_loader: true,
        })
        .then((res) => {
          if (res) {
            this.offset = res.next_offset;
          }
          if (res && res.list && res.list.length > 0) {
            this.orderList = res.list;
          } else {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setTitle("No Data Available");
            }
          }
          if (page >= 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
                this.$refs.listPagination.setCurrentPage(page + 1);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        })
        .catch((error) => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
            this.$refs.errorComponent.setTitle("");
          }
          console.error(error);
        });
    },
    openRescheduleModel(uuid, pickup_date, pickup_time) {
      this.uuid = uuid;
      this.pickup_date = pickup_date;
      this.pickup_time = pickup_time;
      this.checkSlot();
    },

    checkSlot() {
      var newArray = [];
      newArray = this.finalrescheduleTimeList;
      for (let i = 0; i < this.rescheduleDateList.length; i++) {
        if (this.compareDates(this.rescheduleDateList[i].date, this.pickup_date)) {
          this.selectedDate = this.rescheduleDateList[i].date;
          break;
        }
      }

      const isToday = this.checkToday(this.pickup_date);

      if (isToday) {
        const currentTime = new Date();
        const currentTimeString = currentTime.toTimeString().split(" ")[0];
        const rescheduleTimeList = newArray.filter(
          (slot) => slot.from_time > currentTimeString
        );

        newArray = rescheduleTimeList;
      }

      for (let i = 0; i < newArray.length; i++) {
        if (newArray[i].title === this.pickup_time) {
          this.selectedTime = newArray[i].title;
          break;
        }
      }

      this.rescheduleTimeList = newArray;
      if (this.rescheduleTimeList.length === 0 && this.rescheduleDateList.length === 0) {
        if (this.$refs.errorComponent1) {
          this.$refs.errorComponent1.setTitle("No Data Available");
        }
      }
    },
    getDetails() {
      this.selectedDate = "";
      this.selectedTime = "";
      this.finalrescheduleTimeList = [];
      this.rescheduleTimeList = [];
      this.rescheduleDateList = [];
      this.$api
        .getAPI({
          _action: "/workshop/detail/" + this.workshop_id,
          _body: {},
          _hide_loader: true,
        })
        .then((res) => {
          if (res?.info?.time_list?.length > 0) {
            this.rescheduleTimeList = res.info.time_list;
            this.finalrescheduleTimeList = res.info.time_list;
          }
          if (res?.info?.date_list?.length > 0) {
            this.rescheduleDateList = res.info.date_list;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    checkToday(dateString) {
      const currentISTDate = new Date().toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
      });
      const currentDate = new Date(currentISTDate).toISOString().split("T")[0];
      const providedDate = dateString.slice(0, 10);
      const isToday = currentDate === providedDate;

      return isToday;
    },

    compareDates(date1, date2) {
      const d1 = new Date(date1);
      const d2 = new Date(date2);
      return (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
      );
    },

    cancelOrder() {
      var obj = {
        reason: this.cancelReason,
        status: "DECLINE",
      };
      if (this.uuid) {
        this.$api
          .putAPI({
            _action: "order/status/" + this.uuid,
            _buttonId: "cancel-order",
            _body: obj,
          })
          .then((res) => {
            if (res) {
              this.cancelReason = "";
              this.uuid = "";
              this.$toast.open({
                message: res.message,
                type: "success",
                duration: 3000,
                position: "top-right",
              });
              this.getOrderList(this.currentPage);
            }
          })
          .catch((error) => {
            this.cancelReason = "";
            this.uuid = "";
            console.error(error);
            this.$toast.open({
              message: "Error",
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          });
      }
    },
    storeUUID(uuid) {
      this.uuid = uuid;
    },
    orderAccept() {
      var obj = {
        status: "ACCEPT",
      };
      if (this.uuid) {
        this.$api
          .putAPI({
            _action: "order/status/" + this.uuid,
            _buttonId: "order-accept",
            _body: obj,
          })
          .then((res) => {
            const closebutton = document.getElementById("close-accept-popup");
            closebutton.click();
            if (res) {
              this.uuid = "";
              this.$toast.open({
                message: res.message,
                type: "success",
                duration: 3000,
                position: "top-right",
              });
              this.getOrderList(this.currentPage);
            }
          })
          .catch((error) => {
            this.uuid = "";
            console.error(error);
            this.$toast.open({
              message: "Error",
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          });
      }
    },
    saveRescheduleDateAndTime() {
      let formateDate = "";
      if (this.selectedDate) {
        formateDate = this.$helperService.formatDateYYMMDD(this.selectedDate);
      } else {
        formateDate = "";
      }
      var obj = {
        status: "RESCHEDULED",
        pickup_time: this.selectedTime,
        pickup_date: formateDate,
      };
      if (this.uuid) {
        this.$api
          .putAPI({
            _action: "order/status/" + this.uuid,
            _buttonId: "save-reschedule-date-time",
            _body: obj,
          })
          .then((res) => {
            const closebutton1 = document.getElementById("close-save-date-time-popup");
            closebutton1.click();
            const closebutton2 = document.getElementById("close-model-popup");
            closebutton2.click();

            if (res) {
              this.$toast.open({
                message: res.message,
                type: "success",
                duration: 3000,
                position: "top-right",
              });
              this.uuid = "";
              this.pickup_date = "";
              this.pickup_time = "";
              this.selectedDate = "";
              this.selectedTime = "";
              this.getOrderList(this.currentPage);
            }
          })
          .catch((error) => {
            this.uuid = "";
            this.pickup_date = "";
            this.pickup_time = "";
            this.selectedDate = "";
            this.selectedTime = "";
            console.error(error);
            this.$toast.open({
              message: "Error",
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          });
      }
    },
    isCheckedDate(date) {
      if (this.selectedDate && date) {
        return this.compareDates(date, this.selectedDate);
      } else {
        false;
      }
    },
    storeDate(date, event) {
      const isChecked = event.target.checked;
      this.pickup_date = date;
      if (isChecked) {
        this.selectedDate = date;
      } else {
        this.selectedDate = "";
      }
      this.checkSlot();
    },
    isCheckedTime(time) {
      if (time && this.selectedTime) {
        return this.selectedTime === time;
      } else {
        return false;
      }
    },
    storeTime(time, event) {
      const isChecked = event.target.checked;
      if (isChecked) {
        this.selectedTime = time;
      } else {
        this.selectedTime = "";
      }
    },
    moveToOrderDetails(uuid) {
      this.$router.push(`/order-details/${uuid}`);
    },
    pageChange(offset) {
      this.$store.commit("setCurrentPage", offset - 1);
      this.getOrderList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getOrderList(this.currentPage);
    },
  },
};
</script>
