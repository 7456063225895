<template>
  <div class="login-onboarding">
    <section>
      <div class="container">
        <!-- <div class="text-center border-bottom py-3">
          <img
            src="static/images/logo-horizontal.svg"
            width="200"
            class="mx-auto"
            alt=""
          />
        </div> -->
        <div class="bg-light px-3">
          <div class="row justify-content-center pt-5 form-style">
            <div class="col-lg-10">
              <div
                class="text-center d-flex justify-content-between align-items-center mb-4 pb-3"
              >
                <a @click="moveToBack" class="cursor-pointer">
                  <svg
                    width="12"
                    height="16"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                      stroke="#001D21"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <h1 class="font-22 mb-0">Cashless Insurance</h1>
                <div></div>
              </div>

              <div class="row mb-4 justify-content-center">
                <div class="col-lg-6 text-center mb-4">
                  <p class="text-secondary">
                    Do you provide cashless insurance claim services?
                  </p>
                  <div
                    class="btn-group check-btns w-100 justify-content-center"
                    role="group"
                  >
                    <input
                      type="radio"
                      name="insurance"
                      class="btn-check"
                      id="Y"
                      autocomplete="off"
                      v-model="insuranceService"
                      :value="'Y'"
                    />
                    <label class="btn btn-outline-primary" for="Y">Yes</label>

                    <input
                      type="radio"
                      name="insurance"
                      class="btn-check"
                      id="N"
                      autocomplete="off"
                      v-model="insuranceService"
                      :value="'N'"
                    />
                    <label class="btn btn-outline-primary" for="N">No</label>
                  </div>
                </div>

                <div class="col-lg-10 d-lg-flex justify-content-center mb-4">
                  <div class="mb-4">
                    <div
                      class="btn-group select-vehicle-boxes logos flex-wrap"
                      role="group"
                      aria-label="Basic checkbox toggle button group" v-if="insuranceService ==`Y`"
                    >
                      <div
                        class="position-relative"
                        v-for="(item, i) in insuranceList"
                        :key="i"
                      >
                        <div class="form-check">
                          <input
                            type="checkbox"
                            class="btn-check form-check-input"
                            :id="item.id"
                            autocomplete="off"
                            true-value="1"
                            false-value="0"
                            @click="handleCheckBox(item.id)"
                            v-model="item.is_ticked"
                          />
                        </div>
                        <label class="btn btn-outline-primary" :for="item.id">
                          <div class="label">{{ item.title }}</div>
                          <img
                            :src="
                              item.icon
                                ? item.icon
                                : 'static/images/default.png'
                            "
                            class="icon"
                            width="110"
                            alt=""
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <ErrorComponent ref="errorComponent" />
                <div class="text-center">
                  <button
                    v-on:click="onConitnuePost"
                    class="btn btn-primary px-5"
                    id="insaurance_post"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ErrorComponent from "@/components/ErrorComponent.vue";
import "./onboarding.scss";
export default {
  name: "CashlessInsurance",
  components: {
    ErrorComponent,
  },

  data() {
    return {
      id: "",
      insuranceList: [],
      selectedid: [],
      insuranceService: null,
      buttonFlag: true,
      page_type: localStorage.getItem("page_type"),
      workshop_count: localStorage.getItem("workshop_count"),
    };
  },

  mounted() {
    if (this.page_type == "ADD_OUTLET") {
      this.id = localStorage.getItem("outlet_workshop_id");
    } else {
      this.id = localStorage.getItem("workshop_id");
    }
    this.getWorkshopProgressList();
    this.getinsauranceData();
  },
  methods: {
    getinsauranceData() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: `get-insurance/` + this.id,
        })
        .then((res) => {
          this.insuranceService = res.is_insurance;
          this.insuranceList = res.list;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
            if (!res.list || res.list.length == 0) {
              this.$refs.errorComponent.setTitle("No Data Available");
            }
          }

          for (let i = 0; i < res.list.length; i++) {
            if (res.list[i].is_ticked == "1") {
              this.selectedid.push(res.list[i].id);
            }
          }

          console.log(" this.selectedid.push", this.selectedid);
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
            this.$refs.errorComponent.setTitle("");
          }
        });
    },
    handleCheckBox(id) {
      if (!this.selectedid.includes(id)) {
        this.selectedid.push(id);
      } else {
        this.selectedid = this.selectedid.filter((item) => item !== id);
      }
    },
    onConitnuePost() {
      console.log("selectedid", this.selectedid);
      if (this.insuranceService == null) {
        this.$toast.open({
          message: "Please Select Cashless Insurance Claim Service",
          type: "error",
          duration: 3000,
          position: "top-right",
        });
        return;
      }

      if (this.insuranceService == "Y") {
        if (!this.selectedid.length > 0) {
          this.$toast.open({
            message: "Please Select Company Name",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          return;
        }
      }

      const obj = {
        is_insurance: this.insuranceService,
        list: this.selectedid,
      };
      console.log("obj", obj);
      this.$api
        .postAPI({
          _action: "/set-insurance/" + this.id,
          _body: obj,
          _buttonId: "insaurance_post",
        })
        .then(() => {
          if (!this.buttonFlag) {
            this.$router.push("/home");
          } else if (this.insuranceService == "N") {
            this.$router.push("/onboarding-steps");
          } else if (this.workshop_count == 1 && this.insuranceService == "Y") {
            this.$router.push("/agent-code");
          } else {
            this.$router.push("/onboarding-steps");
          }
        })
        .catch(() => {});
    },
    moveToBack() {
      if (this.page_type == "ONBOARDING") {
        this.$router.push("/onboarding-steps");
      } else {
        this.$router.go(-1);
      }
    },
    getWorkshopProgressList() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: "/get-workshop-progress/" + this.id,
          _body: {},
          _hide_loader: true,
        })
        .then((res) => {
          if (res && res.list && res.list.length > 0) {
            this.list = res.list;
            for (var i = 0; i < this.list.length; i++) {
              if (
                this.list[i].is_complete == "N" &&
                (this.list[i].title != "PHOTOS" && this.list[i].title != "COST")
              ) {
                this.buttonFlag = true;
                break;
              } else {
                this.buttonFlag = false;
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },
  },
};
</script>

