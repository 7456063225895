<template>
  <div class="home-page">
    <div class="container py-5">
      <div class="card p-4 rounded-4 bg-secondary-light border-0 mb-4">
        <div class="row align-items-center">
          <div class="col-lg-6 ps-lg-5">
            <h1>Refer a friend & earn ₹100</h1>
            <p>Earn INR 100 on every referral</p>
            <div class="d-flex gap-2 align-items-center">
              <button class="btn btn-primary btn-sm">Refer Now</button>
              <span class="font-12 text-secondary">* T&C Apply.</span>
            </div>
          </div>
          <div class="col-lg-6 text-center">
            <img
              src="static/images/reffer-img.svg"
              width="350"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="card p-4 rounded-4 bg-light border-0 mb-4">
        <p class="font-18 font-medium">Order</p>
        <div class="row">
          <div class="col-lg mb-4">
            <div
              class="card p-4 text-center border rounded-3 cursor-pointer"
              v-on:click="handleRedirection(`NEW_ORDER`)"
            >
              <img
                src="static/images/new-order.svg"
                width="70"
                height="70"
                alt=""
                class="mb-3 mx-auto"
              />
              <p class="text-brand font-18">New Orders</p>
              <div
                class="px-2 py-1 bg-danger-subtle border border-danger font-14 d-inline rounded mx-auto"
              >
                {{ OrderCountList.new_order_count }}
              </div>
            </div>
          </div>
          <div class="col-lg mb-4">
            <div
              class="card p-4 text-center border rounded-3 cursor-pointer"
              v-on:click="handleRedirection(`IN_PROGRESS`)"
            >
              <img
                src="static/images/inprogress.svg"
                width="70"
                height="70"
                alt=""
                class="mb-3 mx-auto"
              />
              <p class="text-brand font-18">InProgress Orders</p>
              <div
                class="px-2 py-1 bg-warning-subtle border border-warning font-14 d-inline rounded mx-auto"
              >
                {{ OrderCountList.inprogress_order_count }}
              </div>
            </div>
          </div>
          <div class="col-lg mb-4">
            <div
              class="card p-4 text-center border rounded-3 cursor-pointer"
              v-on:click="handleRedirection(`RESCHEDULED`)"
            >
              <img
                src="static/images/icon-reshcedule.svg"
                width="70"
                height="70"
                alt=""
                class="mb-3 mx-auto"
              />
              <p class="text-brand font-18">Rescheduled</p>
              <div
                class="px-2 py-1 bg-info-subtle border-info border font-14 d-inline rounded mx-auto"
              >
                {{ OrderCountList.rescheduled_order_count }}
              </div>
            </div>
          </div>
          <div class="col-lg mb-4">
            <div
              class="card p-4 text-center border rounded-3 cursor-pointer"
              v-on:click="handleRedirection(`COMPLETED`)"
            >
              <img
                src="static/images/technitian.svg"
                width="70"
                height="70"
                alt=""
                class="mb-3 mx-auto"
              />
              <p class="text-brand font-18">Completed</p>
              <div
                class="px-2 py-1 bg-info-subtle border-info border font-14 d-inline rounded mx-auto"
              >
                {{ OrderCountList.complete_order_count }}
              </div>
            </div>
          </div>
          <div class="col-lg">
            <div
              class="card p-4 text-center border rounded-3 cursor-pointer"
              v-on:click="handleRedirection(`QUOTATION`)"
            >
              <img
                src="static/images/quotation.svg"
                width="70"
                height="70"
                alt=""
                class="mb-3 mx-auto"
              />
              <p class="text-brand font-18">Quotations</p>
              <div
                class="px-2 py-1 bg-info-subtle border-info border font-14 d-inline rounded mx-auto"
              >
                {{ OrderCountList?.quotation_count }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2 class="font-18 font-medium mb-3">Need Help?</h2>
        <div class="row justify-content-center">
          <div class="col-lg-6 mb-3" v-on:click="openWhatsapp()">
            <div class="card p-3 rounded-4 cursor-pointer">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex gap-2 align-items-center">
                  <img src="static/images/icon-whatsapp.svg" width="50" height="50" />
                  <div>
                    <h3 class="font-16 font-medium mb-1">Ask us on whatsapp</h3>
                    <p class="mb-0 text-secondary">Get instant support</p>
                  </div>
                </div>
                <a href="">
                  <svg
                    width="10"
                    height="14"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L1 9"
                      stroke="#001D21"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 mb-3" v-on:click="$router.push('/request-callback')">
            <div class="card p-3 rounded-4 cursor-pointer">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex gap-2 align-items-center">
                  <img src="static/images/icon-callback.svg" width="50" height="50" />
                  <div>
                    <h3 class="font-16 font-medium mb-1">Request a callback</h3>
                    <p class="mb-0 text-secondary">Our team will contact you</p>
                  </div>
                </div>
                <a href="">
                  <svg
                    width="10"
                    height="14"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L1 9"
                      stroke="#001D21"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 mb-3" v-on:click="$router.push('/faq')">
            <div class="card p-3 rounded-4 cursor-pointer">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex gap-2 align-items-center">
                  <img src="static/images/icon-faq.svg" width="50" height="50" />
                  <div>
                    <h3 class="font-16 font-medium mb-1">Frequently asked questions</h3>
                    <p class="mb-0 text-secondary">Our team will contact you</p>
                  </div>
                </div>
                <a href="">
                  <svg
                    width="10"
                    height="14"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L1 9"
                      stroke="#001D21"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div class="col-lg-6 mb-3" v-on:click="sendEmail()">
            <div class="card p-3 rounded-4 cursor-pointer">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex gap-2 align-items-center">
                  <img src="static/images/icon-email.svg" width="50" height="50" />
                  <div>
                    <h3 class="font-16 font-medium mb-1">Mail your query</h3>
                    <p class="mb-0 text-secondary">Get instant support</p>
                  </div>
                </div>
                <a href="">
                  <svg
                    width="10"
                    height="14"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L1 9"
                      stroke="#001D21"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button
    type="button"
    hidden
    id="add-partner-details"
    class="btn btn-primary"
    data-bs-toggle="modal"
    data-bs-target="#AddPartnerModal"
  ></button>

  <div
    class="modal fade"
    id="AddPartnerModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    data-bs-backdrop="static"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">AGREEMENT</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="close-modal"
            hidden="true"
          ></button>
        </div>
        <div class="modal-body">
          <iframe :src="content" width="100%" height="600px"></iframe>
        </div>
        <div class="modal-footer" v-if="content && user_type == 'OWNER'">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="acknowledged"
            true-value="Y"
            false-value="N"
            id="acknowledged"
          />
          <label class="form-check-label" for="acknowledged"> I Acknowledged </label>
          <button
            type="button"
            class="btn btn-primary"
            id="save-data"
            :disabled="acknowledged == 'N' || user_type != 'OWNER'"
            v-on:click="send()"
          >
            I Acknowledged
          </button>
        </div>
        <div class="modal-footer" v-else>
          <h3 style="color: #004852">
            Kindly ask the owner or partner to log in and accept to continue.
          </h3>

          <button
            type="button"
            class="logout btn-primary"
            id="save-data"
            v-on:click="handleLogout()"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import "./onboarding.scss";
export default {
  name: "HomePage",
  components: {},

  data() {
    return {
      workshop_id: localStorage.getItem("workshop_id"),
      workshop_name: localStorage.getItem("workshop_name"),
      OrderCountList: {
        new_order_count: 0,
        inprogress_order_count: 0,
        rescheduled_order_count: 0,
        complete_order_count: 0,
        quotation_count: 0,
      },
      setting: {},
      user_type: "",
      content: "",
      acknowledged: "N",
    };
  },

  mounted() {
    this.getMeCall();
    localStorage.setItem("page_type", "HOME");
    this.$store.commit("setPageType", "HOME");
    localStorage.setItem("is_cost_skip", "N");
    localStorage.setItem("is_photo_skip", "N");
    this.GetOrderCountData();
    this.GetSettingData();
  },
  methods: {
    getMeCall() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.user_type = res.result.type;
          // if (res.result.is_acknowledged == "N") {
          //   this.getRegisterCall();
          // }
        })
        .catch(() => {});
    },
    getRegisterCall() {
      this.$api
        .getAPI({
          _action: `get-agreement`,
        })
        .then((res) => {
          this.content = res.url;
          document.getElementById("add-partner-details").click();
        })
        .catch(() => {
          this.$toast.open({
            message: "Please contact to administration.",
            type: "error",
            duration: 10000,
            position: "top-right",
          });
          localStorage.clear();
          window.location.href = "/home";
        });
    },
    send() {
      this.$api
        .postAPI({
          _action: `acknowledged`,
          _buttonId: "save-data",
        })
        .then(() => {
          document.getElementById("close-modal").click();
          this.getMeCall();
        })
        .catch(() => {});
    },
    handleLogout() {
      this.$swal({
        title: "Are you sure?",
        text: "You want to logout ",
        icon: "Warning",
        showCancelButton: true,
        confirmButtonText: "Yes,",
        cancelButtonText: "No, cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(true);
          }
          this.$api
            .getAPI({
              _action: "logout",
            })
            .then(() => {
              if (this.$refs.errorComponent) {
                this.$refs.errorComponent.showLoader(false);
              }
              document.getElementById("close-modal").click();
              localStorage.clear();
              this.$api.clearCache();
              this.$store.commit("setPageType", "");
              this.$store.commit("setAccessToken", "");
              this.$store.commit("setWorkshopId", "");
              this.$router.push("/login");
            })
            .catch(() => {
              if (this.$refs.errorComponent) {
                this.$refs.errorComponent.showLoader(false);
              }
            });
        }
      });
    },
    sendEmail() {
      var mail = "mailto:" + this.setting.email;
      var a = document.createElement("a");
      a.href = mail;
      a.click();
    },
    GetOrderCountData() {
      this.$api
        .getAPI({
          _action: `order/count/${this.workshop_id}`,
        })
        .then((res) => {
          this.OrderCountList = res.info;
        })
        .catch(() => {});
    },
    GetSettingData() {
      this.$api
        .getAPI({
          _action: `app-support`,
        })
        .then((res) => {
          this.setting = res.info;
        })
        .catch(() => {});
    },
    handleRedirection(type) {
      this.$sharedService.setMapValue("order_type", type);
      this.$router.push("/orders");
    },
    openWhatsapp() {
      window.location.href =
        "https://api.whatsapp.com/send?phone=" + this.setting.whatsapp_number;
    },
  },
};
</script>

