<template>
  <div class="login-onboarding">
    <section>
      <div class="container">
        <!-- <div class="text-center border-bottom py-3">
          <img
            src="static/images/logo-horizontal.svg"
            width="200"
            class="mx-auto"
            alt=""
          />
        </div> -->
        <div class="bg-light px-3 pb-5">
          <div class="row justify-content-center pt-5 form-style">
            <div class="col-lg-10">
              <div
                class="text-center d-flex justify-content-between align-items-center mb-4 pb-3"
              >
                <a @click="$router.go(-1)" class="cursor-pointer">
                  <svg
                    width="12"
                    height="16"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                      stroke="#001D21"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <h1 class="font-22 mb-0">Agent Code</h1>
                <div>
                  <a @click="moveToPage" class="text-brand cursor-pointer"
                    >Skip</a
                  >
                </div>
              </div>

              <div class="row mb-4 justify-content-center">
                <div class="col-lg-12 text-center mb-4">
                  <p class="text-secondary">You got an agent code?</p>
                </div>
                <div class="col-6">
                  <div style="display: flex; flex-direction: row">
                    <v-otp-input
                      ref="otpInput"
                      input-classes="otp-input form-control text-center"
                      separator="&nbsp;&nbsp;&nbsp;&nbsp;"
                      inputType="text"
                      :num-inputs="4"
                      v-model:value="code"
                      :should-auto-focus="true"
                      :should-focus-order="true"
                      @on-change="handleOnChange"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center" v-if="buttonFlag">
                <button
                  class="btn btn-primary px-5"
                  @click="setAgentcode"
                  :disabled="code.length < 4"
                  id="agent_post"
                >
                  Continue
                </button>
              </div>
              <div class="text-center" v-else>
                <button
                  class="btn btn-primary px-5"
                  data-bs-toggle="modal"
                  data-bs-target="#ModalSuccess"
                  @click="setAgentcode"
                  :disabled="code.length < 4"
                  id="agent_post"
                >
                  Continue
                </button>
              </div>

              <div class="text-center mt-3">
                <!-- <button @click="clearInput" class="btn btn-secondary">Clear Input</button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- Modal success -->
  <div
    class="modal fade"
    id="ModalSuccess"
    tabindex="-1"
    aria-labelledby="ModalSuccessLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center pt-4">
          <img
            src="/static/images/success-icon.svg"
            width="110"
            alt=""
            class="mb-4"
          />
          <h2 class="font-bold font-22">Onboarding Completed!</h2>
          <p class="text-secondary">
            Congratulations! Your onboarding process has been successfully
            completed.
          </p>
        </div>
        <div class="modal-footer border-0 pt-0 pb-4">
          <a href="/home" type="button" class="btn btn-primary w-100">Done</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./onboarding.scss";
import $ from "jquery";
import VOtpInput from "vue3-otp-input";
export default {
  name: "AgentCode",
  components: {
    VOtpInput,
  },

  data() {
    return {
      code: "",
      buttonFlag: true,
      workshop_id: "",
      page_type: localStorage.getItem("page_type"),
    };
  },

  mounted() {
    if (this.page_type == "ADD_OUTLET") {
      this.workshop_id = localStorage.getItem("outlet_workshop_id");
      this.getWorkShopProgress();
    } else {
      this.workshop_id = localStorage.getItem("workshop_id");
      this.getWorkShopProgress();
    }
    $(".btn-check").change(function () {
      $(this).closest(".form-check").toggleClass("selected", this.checked);
    });
  },

  methods: {
    handleOnChange(value) {
      this.code = value;
    },
    setAgentcode() {
      const obj = {
        code: this.code,
      };
      this.$api
        .postAPI({
          _action: "set-agent-code",
          _body: obj,
          _buttonId: "agent_post",
        })
        .then(() => {
           if(this.buttonFlag){
             this.$router.push("/onboarding-steps")
           }else {
             this.$router.push("/home")
           }
        })
        .catch(() => {});
    },
    getWorkShopProgress() {
      this.$api
        .getAPI({
          _action: "/get-workshop-progress/" + this.workshop_id,
          _body: {},
          _hide_loader: true,
        })
        .then((res) => {
          if (res && res.list && res.list.length > 0) {
            for (var i = 0; i < res.list.length; i++) {
              if (
                res.list[i].is_complete == "N" &&
                (res.list[i].title != "PHOTOS" && res.list[i].title != "COST")
              ) {
                this.buttonFlag = true;
                break;
              } else {
                this.buttonFlag = false;
              }
            }
          }
        });
    },
    moveToPage() {
      if (this.buttonFlag) {
        this.$router.push("/onboarding-steps");
      } else {
        this.$router.push("/home");
      }
    },
  },
};
</script>

<style scoped>
.otp-input {
  width: 50px;
  margin-right: 10px;
  font-size: 24px;
}
</style>