<template>
  <div class="page-content">
    <section v-if="this.orderDetails.order_status">
      <div class="container">
        <div
          class="d-flex align-items-center justify-content-between mb-4 pb-3 gap-3 border-bottom"
        >
          <div class="d-flex gap-3">
            <a
              href="javascript:void(0)"
              v-on:click="this.$router.push('/orders')"
              class=" "
            >
              <svg
                width="12"
                height="16"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                  stroke="#001D21"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
            <h1 class="font-22 mb-0">Order Details</h1>
          </div>
          <div class="text-end">
            <span class="me-3 font-bold"
              >Order ID: {{ orderDetails?.order_no || "-" }}</span
            >
            <span class="text-secondary">{{
              $helperService.dateFormateForOrder(orderDetails?.created_at)
            }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div
              class="card mb-4"
              v-show="
                orderDetails !== null &&
                orderDetails !== undefined &&
                Object.keys(orderDetails).length > 0
              "
            >
              <div class="card-header">
                <div class="row">
                  <div class="col-lg-6">
                    <span class="text-secondary">Booking slot </span>
                    <span> {{ orderDetails?.pickup_time || "-" }}</span>
                  </div>
                  <div class="col-lg-6 text-lg-end">
                    <span class="text-secondary">Order Placed </span>
                    <span>
                      {{
                        $helperService.dateFormateForOrder(orderDetails?.created_at)
                      }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="border p-2 rounded text-center mb-2">
                      <img
                        class="img-fluid mb-2"
                        style="height: 250px"
                        :src="
                          orderDetails?.uservehicle?.manufacturer_model?.icon ||
                          'static/images/default.png'
                        "
                        alt=""
                      />
                      <p class="mb-0">
                        {{ orderDetails?.uservehicle?.manufacturer_model?.title || "-" }}
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-12 border-bottom">
                    <div
                      class="p-2 bg-secondary-light d-inline-block rounded px-3 mb-2"
                      v-if="orderDetails?.uservehicle?.vehicle_registration_number"
                    >
                      {{ orderDetails?.uservehicle?.vehicle_registration_number || "-" }}
                    </div>
                    <div class="mb-2">
                      <span class="text-secondary">Fuel type:</span>
                      <span class="ms-2">{{
                        orderDetails?.uservehicle?.fuel?.title || "-"
                      }}</span>
                    </div>
                    <div class="mb-2">
                      <span class="text-secondary">Transmission type:</span>
                      <span class="ms-2">{{
                        orderDetails?.uservehicle?.transmission?.title || "-"
                      }}</span>
                    </div>
                    <div class="mb-3">
                      <span class="text-secondary">Services:</span>
                      <template v-if="orderDetails && orderDetails.categories">
                        <span class="ms-2">{{
                          orderDetails.categories
                            .map((obj) => obj.category.title)
                            .join(", ")
                        }}</span>
                      </template>
                    </div>
                  </div>
                  <div class="col-lg-12 px-2">
                    <table class="table table-borderless border-bottom">
                      <tbody>
                        <tr
                          v-if="
                            orderDetails?.order_quotation?.workshop_quotation?.quotation
                          "
                        >
                          <td>
                            <span class="text-secondary">Quotation ID</span>
                          </td>
                          <td>
                            {{
                              orderDetails?.order_quotation?.workshop_quotation?.quotation
                                ?.quotation_no
                            }}
                          </td>
                        </tr>
                        <tr v-if="orderDetails?.order_quotation?.workshop_quotation">
                          <td>
                            <span class="text-secondary">Selected Quote</span>
                          </td>
                          <td>
                            {{
                              orderDetails?.order_quotation?.workshop_quotation?.brand
                                ?.title
                            }}
                          </td>
                        </tr>

                        <tr v-if="orderDetails?.order_quotation?.workshop_quotation">
                          <td>
                            <span class="text-secondary">Quotation Price</span>
                          </td>
                          <td>
                            ₹{{
                              orderDetails?.order_quotation?.workshop_quotation?.price
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="table table-borderless">
                      <tbody>
                        <tr v-if="orderDetails?.is_pickup == 'Y'">
                          <td><span class="text-secondary">Pick-up</span></td>
                          <td>
                            {{
                              orderDetails?.workshop?.pickup_drop?.pickup_cost || "Free"
                            }}
                          </td>
                        </tr>
                        <tr v-if="orderDetails?.is_drop == 'Y'">
                          <td><span class="text-secondary">Drop</span></td>
                          <td>
                            {{ orderDetails?.workshop?.pickup_drop?.drop_cost || "Free" }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="text-secondary">Booking Date</span>
                          </td>
                          <td>
                            {{
                              $helperService.dateFormat(orderDetails?.pickup_date || "-")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="text-secondary">Booking Slot</span>
                          </td>
                          <td>
                            {{ orderDetails?.pickup_time || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="text-secondary">Order Placed</span>
                          </td>
                          <td>
                            {{
                              $helperService.dateFormateForOrder(orderDetails?.created_at)
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-12">
                    <!-- <button class="btn btn-outline-primary btn-sm me-3">
                      <i class="bi bi-telephone"></i> Call
                    </button> -->
                    <button
                      class="btn btn-outline-primary btn-sm"
                      v-if="this.orderDetails.conversation_id"
                      v-on:click="
                        this.$router.push('/chat/' + this.orderDetails.conversation_id)
                      "
                    >
                      <i class="bi bi-chat-left-text"></i> Chat
                    </button>
                    <button
                      class="btn btn-outline-primary btn-sm"
                      v-else
                      v-on:click="
                        this.$sharedService.setMapValue('orderId', this.orderDetails.id);
                        this.$router.push('/order-chat');
                      "
                    >
                      <i class="bi bi-chat-left-text"></i> Chat
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-footer bg-white d-lg-flex gap-3 justify-content-center">
                <button
                  class="btn btn-primary px-5"
                  data-bs-toggle="modal"
                  data-bs-target="#NoshowModal"
                  v-if="
                    (orderDetails &&
                      orderDetails.order_flow == 'FLOW_5' &&
                      orderDetails.order_status == 'IN_PROGRESS') ||
                    (orderDetails.order_flow == 'FLOW_3' &&
                      orderDetails.order_status == 'IN_PROGRESS') ||
                    (orderDetails.order_flow != 'FLOW_3' &&
                      (orderDetails.order_status == 'ESTIMATE_ACCEPTED' ||
                        orderDetails.order_status == 'COST_ACCEPTED'))
                  "
                >
                  No Show
                </button>
                <button
                  v-if="
                    orderDetails?.order_status === 'PENDING' ||
                    orderDetails?.order_status === 'RESCHEDULED_BY_CUSTOMER'
                  "
                  class="btn btn-primary px-5"
                  data-bs-toggle="modal"
                  data-bs-target="#AcceptModal"
                >
                  Accept
                </button>
                <button
                  v-if="
                    orderDetails?.order_status === 'PENDING' ||
                    orderDetails?.order_status === 'RESCHEDULED_BY_CUSTOMER'
                  "
                  class="btn btn-outline-danger bg-danger-subtle rounded-3 w-100"
                  style="padding: 10px 20px"
                  data-bs-toggle="modal"
                  data-bs-target="#CancelModal"
                >
                  Decline
                </button>
                <button
                  v-if="orderDetails?.order_status === 'PENDING'"
                  class="btn btn-outline-primary w-100"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#RescheduleBlade"
                  v-on:click="checkSlot"
                >
                  Reschedule
                </button>
              </div>
            </div>
            <template v-if="orderDetails.order_flow == 'FLOW_1'">
              <div
                class="card p-4 mb-4"
                v-if="
                  orderDetails.order_status !== 'CANCELLED' &&
                  orderDetails.order_status !== 'PENDING'
                "
              >
                <div class="text-center">
                  <h4 class="font-18">Documents</h4>
                  <p v-if="is_job_card == 'N'">Upload documents</p>
                </div>
                <div class="row justify-content-center">
                  <div class="col-lg-12">
                    <div
                      class="upload-btn-container mb-4"
                      v-if="
                        is_job_card == 'N' &&
                        (orderDetails.order_status == 'WORK_START' ||
                          orderDetails.order_status == 'IN_PROGRESS' ||
                          orderDetails.order_status == 'SEND_COST' ||
                          orderDetails.order_status == 'REVICE_COST' ||
                          orderDetails.order_status == 'COST_ACCEPTED')
                      "
                    >
                      <input
                        type="file"
                        accept="image/jpeg, image/png, image/jpg"
                        @change="uploadDocument($event)"
                      />
                      <label for="preview-pan-0">
                        <div class="py-4 text-center">
                          <div class="mb-3">
                            <img
                              src="static/images/icon-file.svg"
                              width="20"
                              class="mx-auto"
                              alt=""
                            />
                          </div>
                          <div class="mb-3 text-secondary">Select document to upload</div>
                          <div>
                            <button class="btn btn-primary">Upload File</button>
                          </div>
                        </div>
                      </label>
                      <div class="text-center">
                        <span class="font-12 text-secondary"
                          >Supported files: Jpeg, PNG, PDF (Max. file size 4 MB)</span
                        >
                      </div>
                    </div>
                    <input
                      hidden
                      type="file"
                      id="job_card-upload"
                      accept="image/jpeg, image/png, image/jpg"
                      @change="uploadDocument($event)"
                    />
                    <div class="mb-3 border-bottom pb-3" v-if="is_job_card == 'Y'">
                      <p class="font-bold mb-2">Job Card</p>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop periodic
                              service details</span
                            >
                          </div>
                          <a
                            v-if="orderDetails.order_status == 'IN_PROGRESS'"
                            class="cursor-pointer text-brand font-bold"
                            data-bs-toggle="modal"
                            data-bs-target="#DocumentModal"
                            >View</a
                          >
                          <a
                            v-else
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(this.job_card)"
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 border-bottom pb-3" v-if="is_estimate == 'Y'">
                      <template
                        v-if="
                          estimate_status == 'ACCEPTED' ||
                          revice_estimate_status == 'ACCEPTED'
                        "
                      >
                        <div class="pb-3">
                          <p class="font-bold mb-2">Estimate</p>
                          <div class="card p-3 rounded-4 bg-secondary-light">
                            <div
                              class="d-flex justify-content-between align-items-center"
                            >
                              <div class="d-flex gap-3 n">
                                <img
                                  src="/static/images/icon-file-color.svg"
                                  width="20"
                                  height="20"
                                /><span
                                  >{{ orderDetails.workshop.workshop_name }} Workshop
                                  estimate details</span
                                >
                              </div>
                              <a
                                class="cursor-pointer text-brand font-bold"
                                v-on:click="
                                  downloadFile(
                                    estimate_status == 'ACCEPTED'
                                      ? estimate
                                      : revice_estimate
                                  )
                                "
                                >Download</a
                              >
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-else-if="orderDetails.estimate_send_count > 2">
                        <div class="justify-content-between mb-3">
                          <div class="row">
                            <div class="col-lg-6">
                              <p class="font-bold mb-2">Estimate</p>
                            </div>
                            <div class="col-lg-6">
                              <p
                                style="float: right"
                                class="bg-warning badge font-medium font-12"
                                v-if="
                                  estimate_status == 'PENDING' &&
                                  revice_estimate_status == 'PENDING'
                                "
                              >
                                <span
                                  v-if="
                                    orderDetails.is_revice_estimate == 'N' &&
                                    orderDetails.order_status == 'IN_PROGRESS'
                                  "
                                  >Sent</span
                                >
                                <span
                                  v-if="
                                    orderDetails.is_revice_estimate == 'Y' &&
                                    orderDetails.order_status == 'REVICE_ESTIMATE'
                                  "
                                  >Revision Requested</span
                                >
                                <span
                                  v-if="
                                    orderDetails.is_revice_estimate == 'N' &&
                                    orderDetails.order_status == 'REVICE_ESTIMATE'
                                  "
                                  >Revision Sent</span
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                        <p
                          class="text-secondary"
                          v-if="
                            estimate_status == 'PENDING' ||
                            revice_estimate_status == 'PENDING'
                          "
                        >
                          Note: estimate sent but waiting for acceptance
                        </p>
                        <ul
                          class="nav nav-pills mb-3 tab-style-1"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link active"
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              Old Estimate
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-profile"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              Revised Estimates
                            </button>
                          </li>
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                          <div
                            class="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                            tabindex="0"
                          >
                            <span
                              class="bg-success badge font-medium font-12 mb-2"
                              v-if="estimate_status != 'PENDING'"
                              >Accepted</span
                            >
                            <div class="card p-3 rounded-4 bg-secondary-light">
                              <div
                                class="d-flex justify-content-between align-items-center"
                              >
                                <div class="d-flex gap-3 n">
                                  <img
                                    src="/static/images/icon-file-color.svg"
                                    width="20"
                                    height="20"
                                  /><span
                                    >{{ orderDetails.workshop.workshop_name }} Workshop
                                    periodic service details</span
                                  >
                                </div>
                                <a
                                  class="cursor-pointer text-brand font-bold"
                                  v-on:click="downloadFile(this.estimate)"
                                  >Download</a
                                >
                              </div>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="pills-profile"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                            tabindex="0"
                          >
                            <span
                              class="bg-success badge font-medium font-12 mb-2"
                              v-if="revice_estimate_status != 'PENDING'"
                              >Accepted</span
                            >
                            <div class="card p-3 rounded-4 bg-secondary-light">
                              <div
                                class="d-flex justify-content-between align-items-center"
                              >
                                <div class="d-flex gap-3 n">
                                  <img
                                    src="/static/images/icon-file-color.svg"
                                    width="20"
                                    height="20"
                                  /><span
                                    >{{ orderDetails.workshop.workshop_name }} Workshop
                                    periodic service details</span
                                  >
                                </div>
                                <a
                                  class="cursor-pointer text-brand font-bold"
                                  v-on:click="downloadFile(this.revice_estimate)"
                                  >Download</a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="pb-3" v-if="estimate">
                          <div class="row">
                            <div class="col-lg-6">
                              <p class="font-bold mb-2">Estimate</p>
                            </div>
                            <div class="col-lg-6">
                              <p
                                style="float: right"
                                class="bg-warning badge font-medium font-12"
                                v-if="
                                  estimate_status == 'PENDING' &&
                                  revice_estimate_status == 'PENDING'
                                "
                              >
                                <span
                                  v-if="
                                    orderDetails.is_revice_estimate == 'N' &&
                                    orderDetails.order_status == 'GENERATE_ESTIMATE'
                                  "
                                  >Sent</span
                                >
                                <span
                                  v-if="
                                    orderDetails.is_revice_estimate == 'Y' &&
                                    orderDetails.order_status == 'REVICE_ESTIMATE'
                                  "
                                  >Revision Requested</span
                                >
                                <span
                                  v-if="
                                    orderDetails.is_revice_estimate == 'N' &&
                                    orderDetails.order_status == 'REVICE_ESTIMATE'
                                  "
                                  >Revision Sent</span
                                >
                              </p>
                            </div>
                          </div>
                          <p class="text-secondary">
                            <span
                              v-if="
                                orderDetails.order_status == 'GENERATE_ESTIMATE' ||
                                (orderDetails.is_revice_estimate == 'N' &&
                                  orderDetails.order_status == 'REVICE_ESTIMATE')
                              "
                              >Note: estimate sent but waiting for acceptance</span
                            >
                          </p>
                          <div class="card p-3 rounded-4 bg-secondary-light">
                            <div
                              class="d-flex justify-content-between align-items-center"
                            >
                              <div class="d-flex gap-3 n">
                                <img
                                  src="/static/images/icon-file-color.svg"
                                  width="20"
                                  height="20"
                                /><span
                                  >{{ orderDetails.workshop.workshop_name }} Workshop
                                  estimate details</span
                                >
                              </div>
                              <a
                                class="cursor-pointer text-brand font-bold"
                                v-on:click="downloadFile(this.estimate)"
                                >Download</a
                              >
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>

                    <div class="mb-3 border-bottom pb-3" v-if="proforma">
                      <p class="font-bold mb-2">Pro-Forma Invoice</p>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop proforma
                              details</span
                            >
                          </div>
                          <a
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(this.proforma)"
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 border-bottom pb-3" v-if="final_invoice">
                      <p class="font-bold mb-2">Final Invoice</p>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop
                              invoice</span
                            >
                          </div>
                          <a
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(this.final_invoice)"
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="card p-4 mb-4"
                v-if="
                  orderDetails.order_status == 'IN_PROGRESS' ||
                  orderDetails.order_status == 'REVICE_COST' ||
                  (orderDetails.is_revice_estimate == 'Y' &&
                    orderDetails.order_status == 'REVICE_ESTIMATE') ||
                  (orderDetails &&
                    (orderDetails.order_status == 'ESTIMATE_ACCEPTED' ||
                      orderDetails.order_status == 'COST_ACCEPTED')) ||
                  orderDetails.order_status == 'WORK_START' ||
                  is_revice_proforma == 'Y'
                "
              >
                <div class="text-center">
                  <button
                    v-on:click="
                      this.$router.push(
                        '/invoice/' + this.orderDetails.uuid + '/GENERATE_ESTIMATE'
                      )
                    "
                    class="btn btn-primary px-5"
                    :disabled="is_job_card == 'N'"
                    v-if="
                      !estimate &&
                      (orderDetails.order_status == 'IN_PROGRESS' ||
                        orderDetails.order_status == 'REVICE_COST')
                    "
                  >
                    Create Estimate
                  </button>
                  <button
                    v-on:click="
                      this.$router.push(
                        '/invoice/' + this.orderDetails.uuid + '/REVICE_ESTIMATE'
                      )
                    "
                    class="btn btn-primary px-5"
                    v-if="
                      orderDetails.is_revice_estimate == 'Y' &&
                      orderDetails.order_status == 'REVICE_ESTIMATE'
                    "
                  >
                    Revise Estimate
                  </button>
                  <button
                    v-if="
                      orderDetails &&
                      (orderDetails.order_status == 'ESTIMATE_ACCEPTED' ||
                        orderDetails.order_status == 'COST_ACCEPTED')
                    "
                    class="btn btn-primary px-5 mb-2"
                    id="work-start"
                    v-on:click="startWork"
                  >
                    Start Work
                  </button>

                  <button
                    v-if="orderDetails && orderDetails.order_status == 'WORK_START'"
                    class="btn btn-primary px-5 mb-2"
                    v-on:click="
                      this.$router.push(
                        '/invoice/' + this.orderDetails.uuid + '/PROFORMA'
                      )
                    "
                  >
                    Create Pro-Forma Invoice
                  </button>

                  <button
                    v-if="is_revice_proforma == 'Y'"
                    class="btn btn-primary px-5 mb-2"
                    v-on:click="
                      this.$router.push(
                        '/invoice/' + this.orderDetails.uuid + '/PROFORMA'
                      )
                    "
                  >
                    Revise Pro-Forma Invoice
                  </button>
                </div>
              </div>
            </template>
            <template v-if="orderDetails.order_flow == 'FLOW_2'">
              <div
                class="card p-4 mb-4"
                v-if="
                  orderDetails.order_status !== 'CANCELLED' &&
                  orderDetails.order_status !== 'PENDING'
                "
              >
                <div class="text-center">
                  <h4 class="font-18">Documents</h4>
                  <p v-if="is_job_card == 'N'">Upload documents</p>
                </div>
                <div class="row justify-content-center">
                  <div class="col-lg-12">
                    <div
                      class="upload-btn-container mb-4"
                      v-if="
                        is_job_card == 'N' &&
                        (orderDetails.order_status == 'WORK_START' ||
                          orderDetails.order_status == 'IN_PROGRESS' ||
                          orderDetails.order_status == 'SEND_COST' ||
                          orderDetails.order_status == 'REVICE_COST' ||
                          orderDetails.order_status == 'COST_ACCEPTED')
                      "
                    >
                      <input
                        type="file"
                        accept="image/jpeg, image/png, image/jpg"
                        @change="uploadDocument($event)"
                      />
                      <label for="preview-pan-0">
                        <div class="py-4 text-center">
                          <div class="mb-3">
                            <img
                              src="static/images/icon-file.svg"
                              width="20"
                              class="mx-auto"
                              alt=""
                            />
                          </div>
                          <div class="mb-3 text-secondary">Select document to upload</div>
                          <div>
                            <button class="btn btn-primary">Upload File</button>
                          </div>
                        </div>
                      </label>
                      <div class="text-center">
                        <span class="font-12 text-secondary"
                          >Supported files: Jpeg, PNG, PDF (Max. file size 4 MB)</span
                        >
                      </div>
                    </div>
                    <input
                      hidden
                      type="file"
                      id="job_card-upload"
                      accept="image/jpeg, image/png, image/jpg"
                      @change="uploadDocument($event)"
                    />
                    <div class="mb-3 border-bottom pb-3" v-if="is_job_card == 'Y'">
                      <p class="font-bold mb-2">Job Card</p>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop periodic
                              service details</span
                            >
                          </div>
                          <a
                            v-if="orderDetails.order_status == 'IN_PROGRESS'"
                            class="cursor-pointer text-brand font-bold"
                            data-bs-toggle="modal"
                            data-bs-target="#DocumentModal"
                            >View</a
                          >
                          <a
                            v-else
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(this.job_card)"
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 border-bottom pb-3" v-if="is_estimate == 'Y'">
                      <template
                        v-if="
                          estimate_status == 'ACCEPTED' ||
                          revice_estimate_status == 'ACCEPTED'
                        "
                      >
                        <div class="pb-3">
                          <div class="row">
                            <div class="col-lg-6">
                              <p class="font-bold mb-2">Estimate</p>
                            </div>
                            <div class="col-lg-6">
                              <p
                                style="float: right; background-color: #3e96a2 !important"
                                class="bg-secondary-light badge font-medium font-12"
                              >
                                Accepted
                              </p>
                            </div>
                          </div>
                          <div class="card p-3 rounded-4 bg-secondary-light">
                            <div
                              class="d-flex justify-content-between align-items-center"
                            >
                              <div class="d-flex gap-3 n">
                                <img
                                  src="/static/images/icon-file-color.svg"
                                  width="20"
                                  height="20"
                                /><span
                                  >{{ orderDetails.workshop.workshop_name }} Workshop
                                  estimate details</span
                                >
                              </div>
                              <a
                                class="cursor-pointer text-brand font-bold"
                                v-on:click="
                                  downloadFile(
                                    estimate_status == 'ACCEPTED'
                                      ? estimate
                                      : revice_estimate
                                  )
                                "
                                >Download</a
                              >
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-else-if="orderDetails.estimate_send_count > 2">
                        <div class="d-flex justify-content-between mb-3">
                          <div class="row">
                            <div class="col-lg-6">
                              <p class="font-bold mb-2">Estimates</p>
                            </div>
                            <div class="col-lg-6">
                              <p
                                style="float: right"
                                class="bg-warning badge font-medium font-12"
                                v-if="
                                  estimate_status == 'PENDING' &&
                                  revice_estimate_status == 'PENDING'
                                "
                              >
                                <span
                                  v-if="
                                    orderDetails.is_revice_estimate == 'N' &&
                                    orderDetails.order_status == 'IN_PROGRESS'
                                  "
                                  >Sent</span
                                >
                                <span
                                  v-if="
                                    orderDetails.is_revice_estimate == 'Y' &&
                                    orderDetails.order_status == 'REVICE_ESTIMATE'
                                  "
                                  >Revision Requested</span
                                >
                                <span
                                  v-if="
                                    orderDetails.is_revice_estimate == 'N' &&
                                    orderDetails.order_status == 'REVICE_ESTIMATE'
                                  "
                                  >Revision Sent</span
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                        <p
                          class="text-secondary"
                          v-if="
                            estimate_status == 'PENDING' ||
                            revice_estimate_status == 'PENDING'
                          "
                        >
                          Note: estimate sent but waiting for acceptance
                        </p>
                        <ul
                          class="nav nav-pills mb-3 tab-style-1"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link active"
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              Old Estimate
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-profile"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              Revised Estimates
                            </button>
                          </li>
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                          <div
                            class="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                            tabindex="0"
                          >
                            <span
                              class="bg-success badge font-medium font-12 mb-2"
                              v-if="estimate_status != 'PENDING'"
                              >Accepted</span
                            >
                            <div class="card p-3 rounded-4 bg-secondary-light">
                              <div
                                class="d-flex justify-content-between align-items-center"
                              >
                                <div class="d-flex gap-3 n">
                                  <img
                                    src="/static/images/icon-file-color.svg"
                                    width="20"
                                    height="20"
                                  /><span
                                    >{{ orderDetails.workshop.workshop_name }} Workshop
                                    periodic service details</span
                                  >
                                </div>
                                <a
                                  class="cursor-pointer text-brand font-bold"
                                  v-on:click="downloadFile(this.estimate)"
                                  >Download</a
                                >
                              </div>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="pills-profile"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                            tabindex="0"
                          >
                            <span
                              class="bg-success badge font-medium font-12 mb-2"
                              v-if="revice_estimate_status != 'PENDING'"
                              >Accepted</span
                            >
                            <div class="card p-3 rounded-4 bg-secondary-light">
                              <div
                                class="d-flex justify-content-between align-items-center"
                              >
                                <div class="d-flex gap-3 n">
                                  <img
                                    src="/static/images/icon-file-color.svg"
                                    width="20"
                                    height="20"
                                  /><span
                                    >{{ orderDetails.workshop.workshop_name }} Workshop
                                    periodic service details</span
                                  >
                                </div>
                                <a
                                  class="cursor-pointer text-brand font-bold"
                                  v-on:click="downloadFile(this.revice_estimate)"
                                  >Download</a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="pb-3" v-if="estimate">
                          <div class="row">
                            <div class="col-lg-6">
                              <p class="font-bold mb-2">Estimates</p>
                            </div>
                            <div class="col-lg-6">
                              <p
                                style="float: right"
                                class="bg-warning badge font-medium font-12"
                                v-if="
                                  estimate_status == 'PENDING' &&
                                  revice_estimate_status == 'PENDING'
                                "
                              >
                                <span
                                  v-if="
                                    orderDetails.is_revice_estimate == 'N' &&
                                    orderDetails.order_status == 'GENERATE_ESTIMATE'
                                  "
                                  >Sent</span
                                >
                                <span
                                  v-if="
                                    orderDetails.is_revice_estimate == 'Y' &&
                                    orderDetails.order_status == 'REVICE_ESTIMATE'
                                  "
                                  >Revision Requested</span
                                >
                                <span
                                  v-if="
                                    orderDetails.is_revice_estimate == 'N' &&
                                    orderDetails.order_status == 'REVICE_ESTIMATE'
                                  "
                                  >Revision Sent</span
                                >
                              </p>
                            </div>
                          </div>
                          <p class="text-secondary">
                            <span
                              v-if="
                                orderDetails.order_status == 'GENERATE_ESTIMATE' ||
                                (orderDetails.is_revice_estimate == 'N' &&
                                  orderDetails.order_status == 'REVICE_ESTIMATE')
                              "
                              >Note: estimate sent but waiting for acceptance</span
                            >
                          </p>
                          <div class="card p-3 rounded-4 bg-secondary-light">
                            <div
                              class="d-flex justify-content-between align-items-center"
                            >
                              <div class="d-flex gap-3 n">
                                <img
                                  src="/static/images/icon-file-color.svg"
                                  width="20"
                                  height="20"
                                /><span
                                  >{{ orderDetails.workshop.workshop_name }} Workshop
                                  estimate details</span
                                >
                              </div>
                              <a
                                class="cursor-pointer text-brand font-bold"
                                v-on:click="downloadFile(this.estimate)"
                                >Download</a
                              >
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>

                    <div class="mb-3 border-bottom pb-3" v-if="proforma">
                      <p class="font-bold mb-2">Pro-Forma Invoice</p>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop proforma
                              details</span
                            >
                          </div>
                          <a
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(this.proforma)"
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 border-bottom pb-3" v-if="final_invoice">
                      <p class="font-bold mb-2">Final Invoice</p>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop
                              invoice</span
                            >
                          </div>
                          <a
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(this.final_invoice)"
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="card p-4 mb-4"
                v-if="
                  (!estimate &&
                    (orderDetails.order_status == 'IN_PROGRESS' ||
                      orderDetails.order_status == 'REVICE_COST')) ||
                  (!revice_estimate && orderDetails.order_status == 'REVICE_ESTIMATE') ||
                  (orderDetails &&
                    (orderDetails.order_status == 'ESTIMATE_ACCEPTED' ||
                      orderDetails.order_status == 'COST_ACCEPTED')) ||
                  orderDetails.order_status == 'WORK_START' ||
                  is_revice_proforma == 'Y' ||
                  orderDetails.is_revice_estimate == 'Y'
                "
              >
                <div class="text-center">
                  <button
                    v-on:click="
                      this.$router.push(
                        '/invoice/' + this.orderDetails.uuid + '/GENERATE_ESTIMATE'
                      )
                    "
                    class="btn btn-primary px-5"
                    v-if="
                      !estimate &&
                      (orderDetails.order_status == 'IN_PROGRESS' ||
                        orderDetails.order_status == 'REVICE_COST')
                    "
                  >
                    Create Estimate
                  </button>
                  <button
                    v-on:click="
                      this.$router.push(
                        '/invoice/' + this.orderDetails.uuid + '/REVICE_ESTIMATE'
                      )
                    "
                    class="btn btn-primary px-5"
                    v-if="
                      orderDetails.is_revice_estimate == 'Y' &&
                      orderDetails.order_status == 'REVICE_ESTIMATE'
                    "
                  >
                    Revise Estimate
                  </button>
                  <button
                    v-if="
                      orderDetails &&
                      (orderDetails.order_status == 'ESTIMATE_ACCEPTED' ||
                        orderDetails.order_status == 'COST_ACCEPTED')
                    "
                    class="btn btn-primary px-5 mb-2"
                    id="work-start"
                    v-on:click="startWork"
                  >
                    Start Work
                  </button>

                  <button
                    v-if="orderDetails && orderDetails.order_status == 'WORK_START'"
                    class="btn btn-primary px-5 mb-2"
                    v-on:click="
                      this.$router.push(
                        '/invoice/' + this.orderDetails.uuid + '/PROFORMA'
                      )
                    "
                  >
                    Create Pro-Forma Invoice
                  </button>

                  <button
                    v-if="is_revice_proforma == 'Y'"
                    class="btn btn-primary px-5 mb-2"
                    v-on:click="
                      this.$router.push(
                        '/invoice/' + this.orderDetails.uuid + '/PROFORMA'
                      )
                    "
                  >
                    Revise Pro-Forma Invoice
                  </button>
                </div>
              </div>
              <div
                class="card p-4 mb-4"
                v-if="
                  orderDetails &&
                  is_estimate == 'N' &&
                  orderDetails.order_status != 'PENDING' &&
                  orderDetails.order_status != 'RESCHEDULED_BY_VENDOR' &&
                  orderDetails.order_status != 'RESCHEDULED_BY_CUSTOMER' &&
                  orderDetails.order_status != 'AUTO_CANCELLED' &&
                  orderDetails.order_status != 'CANCELLED' &&
                  orderDetails.order_status != 'NO_SHOW' &&
                  orderDetails.order_status != 'CLOSED'
                "
              >
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <p
                    class="font-18 font-bold m-0"
                    v-if="orderDetails.order_status == 'REVICE_COST'"
                  >
                    Revised Estimate Cost
                  </p>
                  <p
                    class="font-18 font-bold m-0"
                    v-else-if="orderDetails.order_status == 'IN_PROGRESS'"
                  >
                    Estimate Cost
                  </p>
                  <p class="font-18 font-bold m-0" v-else>Estimate Cost</p>
                  <p
                    style="float: right"
                    class="bg-warning badge font-medium font-12"
                    v-if="
                      orderDetails.order_status == 'REVICE_COST' ||
                      orderDetails.order_status == 'SEND_COST'
                    "
                  >
                    <span v-if="orderDetails.order_status == 'REVICE_COST'"
                      >Revision Requested</span
                    >
                    <span v-else-if="orderDetails.order_status == 'SEND_COST'">Sent</span>
                  </p>
                  <p
                    style="float: right; background-color: #3e96a2 !important"
                    class="bg-secondary-light badge font-medium font-12"
                    v-if="
                      orderDetails.order_status == 'COST_ACCEPTED' ||
                      orderDetails.order_status == 'WORK_START' ||
                      orderDetails.order_status == 'COMPLETED' ||
                      orderDetails.order_status == 'DELIVERED' ||
                      orderDetails.order_status == 'CLOSED'
                    "
                  >
                    Accepted
                  </p>
                </div>
                <input
                  :disabled="
                    orderDetails.order_status == 'SEND_COST' ||
                    orderDetails.order_status == 'COST_ACCEPTED' ||
                    orderDetails.order_status == 'WORK_START' ||
                    orderDetails.order_status == 'COMPLETED' ||
                    orderDetails.order_status == 'DELIVERED' ||
                    orderDetails.order_status == 'CLOSED'
                  "
                  type="text"
                  class="form-control mb-3"
                  placeholder="Enter amount"
                  id=""
                  v-model="orderDetails.estimate_cost"
                />
                <button
                  v-if="
                    orderDetails &&
                    (orderDetails.order_status == 'IN_PROGRESS' ||
                      orderDetails.order_status == 'SEND_COST' ||
                      orderDetails.order_status == 'REVICE_COST')
                  "
                  :disabled="orderDetails.order_status == 'SEND_COST'"
                  class="btn btn-primary px-5 mt-3"
                  id="estimate-payment"
                  v-on:click="sendCost"
                >
                  Send
                </button>
                <p class="font-12 text-secondary mb-0">
                  Note: This amount will be the approx cost.
                </p>
              </div>
            </template>
            <template v-if="orderDetails.order_flow == 'FLOW_3'">
              <div
                class="card p-4 mb-4"
                v-if="
                  orderDetails.order_status !== 'CANCELLED' &&
                  orderDetails.order_status !== 'PENDING'
                "
              >
                <div class="text-center">
                  <h4 class="font-18">Documents</h4>
                  <p v-if="is_job_card == 'N'">Upload documents</p>
                </div>
                <div class="row justify-content-center">
                  <div class="col-lg-12">
                    <div
                      class="upload-btn-container mb-4"
                      v-if="
                        is_job_card == 'N' &&
                        (orderDetails.order_status == 'WORK_START' ||
                          orderDetails.order_status == 'IN_PROGRESS' ||
                          orderDetails.order_status == 'SEND_COST' ||
                          orderDetails.order_status == 'REVICE_COST' ||
                          orderDetails.order_status == 'COST_ACCEPTED')
                      "
                    >
                      <input
                        type="file"
                        accept="image/jpeg, image/png, image/jpg"
                        @change="uploadDocument($event)"
                      />
                      <label for="preview-pan-0">
                        <div class="py-4 text-center">
                          <div class="mb-3">
                            <img
                              src="static/images/icon-file.svg"
                              width="20"
                              class="mx-auto"
                              alt=""
                            />
                          </div>
                          <div class="mb-3 text-secondary">Select document to upload</div>
                          <div>
                            <button class="btn btn-primary">Upload File</button>
                          </div>
                        </div>
                      </label>
                      <div class="text-center">
                        <span class="font-12 text-secondary"
                          >Supported files: Jpeg, PNG, PDF (Max. file size 4 MB)</span
                        >
                      </div>
                    </div>
                    <input
                      hidden
                      type="file"
                      id="job_card-upload"
                      accept="image/jpeg, image/png, image/jpg"
                      @change="uploadDocument($event)"
                    />

                    <div class="mb-3 border-bottom pb-3" v-if="is_job_card == 'Y'">
                      <p class="font-bold mb-2">Job Card</p>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop periodic
                              service details</span
                            >
                          </div>
                          <a
                            v-if="orderDetails.order_status == 'IN_PROGRESS'"
                            class="cursor-pointer text-brand font-bold"
                            data-bs-toggle="modal"
                            data-bs-target="#DocumentModal"
                            >View</a
                          >
                          <a
                            v-else
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(this.job_card)"
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 border-bottom pb-3" v-if="proforma">
                      <p class="font-bold mb-2">Pro-Forma Invoice</p>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop proforma
                              details</span
                            >
                          </div>
                          <a
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(this.proforma)"
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 border-bottom pb-3" v-if="final_invoice">
                      <p class="font-bold mb-2">Final Invoice</p>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop
                              invoice</span
                            >
                          </div>
                          <a
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(this.final_invoice)"
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="card p-4 mb-4"
                v-if="
                  ((orderDetails.order_flow == 'FLOW_1' ||
                    orderDetails.order_flow == 'FLOW_2') &&
                    !estimate &&
                    (orderDetails.order_status == 'IN_PROGRESS' ||
                      orderDetails.order_status == 'REVICE_COST')) ||
                  (!revice_estimate &&
                    (orderDetails.order_flow == 'FLOW_1' ||
                      orderDetails.order_flow == 'FLOW_2') &&
                    orderDetails.order_status == 'REVICE_ESTIMATE') ||
                  (orderDetails &&
                    ((orderDetails.order_flow != 'FLOW_3' &&
                      (orderDetails.order_status == 'ESTIMATE_ACCEPTED' ||
                        orderDetails.order_status == 'COST_ACCEPTED')) ||
                      (orderDetails.order_flow == 'FLOW_3' &&
                        orderDetails.order_status == 'IN_PROGRESS'))) ||
                  (orderDetails && orderDetails.order_status == 'WORK_START') ||
                  is_revice_proforma == 'Y'
                "
              >
                <div class="text-center">
                  <button
                    v-on:click="
                      this.$router.push(
                        '/invoice/' + this.orderDetails.uuid + '/GENERATE_ESTIMATE'
                      )
                    "
                    class="btn btn-primary px-5"
                    :disabled="is_job_card == 'N'"
                    v-if="
                      (orderDetails.order_flow == 'FLOW_1' ||
                        orderDetails.order_flow == 'FLOW_2') &&
                      !estimate &&
                      (orderDetails.order_status == 'IN_PROGRESS' ||
                        orderDetails.order_status == 'REVICE_COST')
                    "
                  >
                    Create Estimate
                  </button>
                  <button
                    v-on:click="
                      this.$router.push(
                        '/invoice/' + this.orderDetails.uuid + '/REVICE_ESTIMATE'
                      )
                    "
                    class="btn btn-primary px-5"
                    v-if="
                      !revice_estimate &&
                      (orderDetails.order_flow == 'FLOW_1' ||
                        orderDetails.order_flow == 'FLOW_2') &&
                      orderDetails.order_status == 'REVICE_ESTIMATE'
                    "
                  >
                    Revise Estimate
                  </button>
                  <button
                    v-if="
                      orderDetails &&
                      ((orderDetails.order_flow != 'FLOW_3' &&
                        (orderDetails.order_status == 'ESTIMATE_ACCEPTED' ||
                          orderDetails.order_status == 'COST_ACCEPTED')) ||
                        (orderDetails.order_flow == 'FLOW_3' &&
                          orderDetails.order_status == 'IN_PROGRESS'))
                    "
                    class="btn btn-primary px-5 mb-2"
                    id="work-start"
                    v-on:click="startWork"
                  >
                    Start Work
                  </button>

                  <button
                    v-if="orderDetails && orderDetails.order_status == 'WORK_START'"
                    class="btn btn-primary px-5 mb-2"
                    v-on:click="
                      this.$router.push(
                        '/invoice/' + this.orderDetails.uuid + '/PROFORMA'
                      )
                    "
                  >
                    Create Pro-Forma Invoice
                  </button>

                  <button
                    v-if="is_revice_proforma == 'Y'"
                    class="btn btn-primary px-5 mb-2"
                    v-on:click="
                      this.$router.push(
                        '/invoice/' + this.orderDetails.uuid + '/PROFORMA'
                      )
                    "
                  >
                    Revise Pro-Forma Invoice
                  </button>
                </div>
              </div>
            </template>
            <template v-if="orderDetails.order_flow == 'FLOW_4'">
              <div
                class="card p-4 mb-4"
                v-if="
                  orderDetails.order_status !== 'CANCELLED' &&
                  orderDetails.order_status !== 'PENDING'
                "
              >
                <div class="text-center">
                  <h4 class="font-18">Documents</h4>
                  <p v-if="is_job_card == 'N' && orderDetails.order_status == 'PENDING'">
                    Upload documents
                  </p>
                </div>
                <div class="row justify-content-center">
                  <div class="col-lg-12">
                    <div
                      class="upload-btn-container mb-4"
                      v-if="
                        is_job_card == 'N' &&
                        (orderDetails.order_status == 'WORK_START' ||
                          orderDetails.order_status == 'IN_PROGRESS' ||
                          orderDetails.order_status == 'SEND_COST' ||
                          orderDetails.order_status == 'REVICE_COST' ||
                          orderDetails.order_status == 'COST_ACCEPTED')
                      "
                    >
                      <input
                        type="file"
                        accept="image/jpeg, image/png, image/jpg"
                        @change="uploadDocument($event)"
                      />
                      <label for="preview-pan-0">
                        <div class="py-4 text-center">
                          <div class="mb-3">
                            <img
                              src="static/images/icon-file.svg"
                              width="20"
                              class="mx-auto"
                              alt=""
                            />
                          </div>
                          <div class="mb-3 text-secondary">Select document to upload</div>
                          <div>
                            <button class="btn btn-primary">Upload File</button>
                          </div>
                        </div>
                      </label>
                      <div class="text-center">
                        <span class="font-12 text-secondary"
                          >Supported files: Jpeg, PNG, PDF (Max. file size 4 MB)</span
                        >
                      </div>
                    </div>
                    <input
                      hidden
                      type="file"
                      id="job_card-upload"
                      accept="image/jpeg, image/png, image/jpg"
                      @change="uploadDocument($event)"
                    />
                    <div class="mb-3 border-bottom pb-3" v-if="is_job_card == 'Y'">
                      <p class="font-bold mb-2">Job Card</p>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop periodic
                              service details</span
                            >
                          </div>
                          <a
                            v-if="orderDetails.order_status == 'IN_PROGRESS'"
                            class="cursor-pointer text-brand font-bold"
                            data-bs-toggle="modal"
                            data-bs-target="#DocumentModal"
                            >View</a
                          >
                          <a
                            v-else
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(this.job_card)"
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>

                    <div
                      class="mb-3 border-bottom pb-3"
                      v-if="final_invoice && is_estimate == 'Y'"
                    >
                      <template
                        v-if="
                          estimate_status == 'ACCEPTED' ||
                          revice_estimate_status == 'ACCEPTED'
                        "
                      >
                        <div class="pb-3">
                          <div class="row">
                            <div class="col-lg-6">
                              <p class="font-bold mb-2">Estimates</p>
                            </div>
                            <div class="col-lg-6">
                              <span
                                style="float: right"
                                class="bg-success badge font-medium font-12 mb-2"
                                >Accepted</span
                              >
                            </div>
                          </div>
                          <div class="card p-3 rounded-4 bg-secondary-light">
                            <div
                              class="d-flex justify-content-between align-items-center"
                            >
                              <div class="d-flex gap-3 n">
                                <img
                                  src="/static/images/icon-file-color.svg"
                                  width="20"
                                  height="20"
                                /><span
                                  >{{ orderDetails.workshop.workshop_name }} Workshop
                                  estimate details</span
                                >
                              </div>
                              <a
                                class="cursor-pointer text-brand font-bold"
                                v-on:click="
                                  downloadFile(
                                    estimate_status == 'ACCEPTED'
                                      ? estimate
                                      : revice_estimate
                                  )
                                "
                                >Download</a
                              >
                            </div>
                          </div>

                          <template
                            v-if="
                              orderDetails.estimate_type == 'IMAGE' ||
                              orderDetails.estimate_type == 'PDF'
                            "
                          >
                            <div class="row mt-5">
                              <div class="col-lg-5">
                                <h3>Material Cost</h3>
                              </div>
                              <div class="col-lg-7">
                                <input
                                  :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                                  type="text"
                                  class="form-control mb-3"
                                  placeholder="Enter amount incl GST"
                                  id=""
                                  v-if="revice_estimate"
                                  v-model="material_cost_new_estimate"
                                />
                                <input
                                  :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                                  type="text"
                                  class="form-control mb-3"
                                  placeholder="Enter amount incl GST"
                                  id=""
                                  v-if="!revice_estimate"
                                  v-model="material_cost_old_estimate"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-5">
                                <h3>Labour Cost</h3>
                              </div>
                              <div class="col-lg-7">
                                <input
                                  :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                                  type="text"
                                  class="form-control mb-3"
                                  placeholder="Enter amount incl GST"
                                  id=""
                                  v-if="revice_estimate"
                                  v-model="labour_cost_new_estimate"
                                />
                                <input
                                  :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                                  type="text"
                                  class="form-control mb-3"
                                  placeholder="Enter amount incl GST"
                                  id=""
                                  v-if="!revice_estimate"
                                  v-model="labour_cost_old_estimate"
                                />
                              </div>
                            </div>
                          </template>
                        </div>
                      </template>
                      <template v-else-if="orderDetails.estimate_send_count > 2">
                        <div class="justify-content-between mb-3">
                          <div class="row">
                            <div class="col-lg-6">
                              <p class="font-bold mb-2">Estimates</p>
                            </div>
                            <div class="col-lg-6">
                              <p
                                style="float: right"
                                class="bg-warning badge font-medium font-12"
                                v-if="
                                  estimate_status == 'PENDING' &&
                                  revice_estimate_status == 'PENDING'
                                "
                              >
                                <span
                                  v-if="
                                    orderDetails.is_revice_estimate == 'N' &&
                                    orderDetails.order_status == 'IN_PROGRESS'
                                  "
                                  >Sent</span
                                >
                                <span
                                  v-if="
                                    orderDetails.is_revice_estimate == 'Y' &&
                                    orderDetails.order_status == 'REVICE_ESTIMATE'
                                  "
                                  >Revision Requested</span
                                >
                                <span
                                  v-if="
                                    orderDetails.is_revice_estimate == 'N' &&
                                    orderDetails.order_status == 'REVICE_ESTIMATE'
                                  "
                                  >Revision Sent</span
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                        <p
                          class="text-secondary"
                          v-if="
                            estimate_status == 'PENDING' ||
                            revice_estimate_status == 'PENDING'
                          "
                        >
                          Note: estimate sent but waiting for acceptance
                        </p>
                        <ul
                          class="nav nav-pills mb-3 tab-style-1"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link active"
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              Old Estimate
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-profile"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              Revised Estimates
                            </button>
                          </li>
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                          <div
                            class="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                            tabindex="0"
                          >
                            <span
                              class="bg-success badge font-medium font-12 mb-2"
                              v-if="estimate_status != 'PENDING'"
                              >Accepted</span
                            >
                            <div class="card p-3 rounded-4 bg-secondary-light">
                              <div
                                class="d-flex justify-content-between align-items-center"
                              >
                                <div class="d-flex gap-3 n">
                                  <img
                                    src="/static/images/icon-file-color.svg"
                                    width="20"
                                    height="20"
                                  /><span
                                    >{{ orderDetails.workshop.workshop_name }} Workshop
                                    periodic service details</span
                                  >
                                </div>
                                <a
                                  class="cursor-pointer text-brand font-bold"
                                  v-on:click="downloadFile(this.estimate)"
                                  >Download</a
                                >
                              </div>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="pills-profile"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                            tabindex="0"
                          >
                            <span
                              class="bg-success badge font-medium font-12 mb-2"
                              v-if="revice_estimate_status != 'PENDING'"
                              >Accepted</span
                            >
                            <div class="card p-3 rounded-4 bg-secondary-light">
                              <div
                                class="d-flex justify-content-between align-items-center"
                              >
                                <div class="d-flex gap-3 n">
                                  <img
                                    src="/static/images/icon-file-color.svg"
                                    width="20"
                                    height="20"
                                  /><span
                                    >{{ orderDetails.workshop.workshop_name }} Workshop
                                    periodic service details</span
                                  >
                                </div>
                                <a
                                  class="cursor-pointer text-brand font-bold"
                                  v-on:click="downloadFile(this.revice_estimate)"
                                  >Download</a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="pb-3" v-if="estimate">
                          <div class="row">
                            <div class="col-lg-6">
                              <p class="font-bold mb-2">Estimates</p>
                            </div>
                            <div class="col-lg-6">
                              <p
                                style="float: right"
                                class="bg-warning badge font-medium font-12"
                                v-if="
                                  estimate_status == 'PENDING' &&
                                  revice_estimate_status == 'PENDING'
                                "
                              >
                                <span
                                  v-if="
                                    orderDetails.is_revice_estimate == 'N' &&
                                    orderDetails.order_status == 'GENERATE_ESTIMATE'
                                  "
                                  >Sent</span
                                >
                                <span
                                  v-if="
                                    orderDetails.is_revice_estimate == 'Y' &&
                                    orderDetails.order_status == 'REVICE_ESTIMATE'
                                  "
                                  >Revision Requested</span
                                >
                                <span
                                  v-if="
                                    orderDetails.is_revice_estimate == 'N' &&
                                    orderDetails.order_status == 'REVICE_ESTIMATE'
                                  "
                                  >Revision Sent</span
                                >
                              </p>
                            </div>
                          </div>
                          <p class="text-secondary">
                            <span
                              v-if="
                                orderDetails.order_status == 'GENERATE_ESTIMATE' ||
                                (orderDetails.is_revice_estimate == 'N' &&
                                  orderDetails.order_status == 'REVICE_ESTIMATE')
                              "
                              >Note: estimate sent but waiting for acceptance</span
                            >
                          </p>
                          <div class="card p-3 rounded-4 bg-secondary-light">
                            <div
                              class="d-flex justify-content-between align-items-center"
                            >
                              <div class="d-flex gap-3 n">
                                <img
                                  src="/static/images/icon-file-color.svg"
                                  width="20"
                                  height="20"
                                /><span
                                  >{{ orderDetails.workshop.workshop_name }} Workshop
                                  estimate details</span
                                >
                              </div>
                              <a
                                class="cursor-pointer text-brand font-bold"
                                v-on:click="downloadFile(this.estimate)"
                                >Download</a
                              >
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>

                    <div
                      class="mb-3 border-bottom pb-3"
                      v-if="
                        proforma &&
                        final_invoice &&
                        orderDetails.is_material_labour_cost_added == 'Y'
                      "
                    >
                      <div class="row">
                        <div class="col-lg-6">
                          <p class="font-bold mb-2">Pro-Forma Invoice</p>
                        </div>
                        <div class="col-lg-6">
                          <span
                            style="float: right"
                            class="bg-success badge font-medium font-12 mb-2"
                            >Accepted</span
                          >
                        </div>
                      </div>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop proforma
                              details</span
                            >
                          </div>
                          <a
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(this.proforma)"
                            >Download</a
                          >
                        </div>
                      </div>

                      <template
                        v-if="
                          orderDetails.estimate_type == 'IMAGE' ||
                          orderDetails.estimate_type == 'PDF'
                        "
                      >
                        <div class="row mt-5">
                          <div class="col-lg-5">
                            <h3>Material Cost</h3>
                          </div>
                          <div class="col-lg-7">
                            <input
                              :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                              type="text"
                              class="form-control mb-3"
                              placeholder="Enter amount incl GST"
                              id=""
                              v-if="orderDetails.estimate_type == 'IMAGE'"
                              v-model="orderDetails.material_cost_image"
                            />
                            <input
                              :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                              type="text"
                              class="form-control mb-3"
                              placeholder="Enter amount incl GST"
                              id=""
                              v-if="orderDetails.estimate_type == 'PDF'"
                              v-model="orderDetails.material_cost"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-5">
                            <h3>Labour Cost</h3>
                          </div>
                          <div class="col-lg-7">
                            <input
                              :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                              type="text"
                              class="form-control mb-3"
                              placeholder="Enter amount incl GST"
                              id=""
                              v-if="orderDetails.estimate_type == 'IMAGE'"
                              v-model="orderDetails.labour_cost_image"
                            />
                            <input
                              :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                              type="text"
                              class="form-control mb-3"
                              placeholder="Enter amount incl GST"
                              id=""
                              v-if="orderDetails.estimate_type == 'PDF'"
                              v-model="orderDetails.labour_cost"
                            />
                          </div>
                        </div>
                      </template>
                    </div>

                    <div class="mb-3 border-bottom pb-3" v-if="final_invoice">
                      <p class="font-bold mb-2">Final Invoice</p>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop
                              invoice</span
                            >
                          </div>
                          <a
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(this.final_invoice)"
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="card p-4 mb-4"
                v-if="
                  orderDetails &&
                  orderDetails.order_status != 'DELIVERED' &&
                  (orderDetails.order_status == 'ESTIMATE_ACCEPTED' ||
                    orderDetails.order_status == 'COST_ACCEPTED')
                "
              >
                <div class="text-center">
                  <button
                    v-if="
                      orderDetails &&
                      (orderDetails.order_status == 'ESTIMATE_ACCEPTED' ||
                        orderDetails.order_status == 'COST_ACCEPTED')
                    "
                    class="btn btn-primary px-5 mb-2"
                    id="work-start"
                    v-on:click="startWork"
                  >
                    Start Work
                  </button>
                </div>
              </div>

              <div
                class="card p-4 mb-4"
                v-if="
                  orderDetails.order_status != 'DELIVERED' &&
                  orderDetails.order_status != 'PENDING'
                "
              >
                <p
                  v-if="
                    !estimate &&
                    (orderDetails.order_status == 'IN_PROGRESS' ||
                      orderDetails.order_status == 'REVICE_COST')
                  "
                >
                  Create Estimate
                </p>
                <button
                  v-on:click="
                    this.$router.push(
                      '/invoice/' + this.orderDetails.uuid + '/GENERATE_ESTIMATE'
                    )
                  "
                  class="btn btn-primary px-5"
                  v-if="
                    !estimate &&
                    (orderDetails.order_status == 'IN_PROGRESS' ||
                      orderDetails.order_status == 'REVICE_COST')
                  "
                >
                  Generate Estimate
                </button>
                <div
                  class="m-3 text-center"
                  v-if="orderDetails.order_status == 'IN_PROGRESS'"
                >
                  <h3>Or</h3>
                </div>

                <div
                  class="mb-3 border-bottom pb-3"
                  v-if="is_estimate == 'Y' && orderDetails.estimate_type == 'IMAGE'"
                >
                  <template
                    v-if="
                      estimate_status == 'ACCEPTED' ||
                      revice_estimate_status == 'ACCEPTED'
                    "
                  >
                    <div class="pb-3">
                      <div class="row">
                        <div class="col-lg-6">
                          <p class="font-bold mb-2">Estimate</p>
                        </div>
                        <div class="col-lg-6">
                          <p
                            style="float: right; background-color: #3e96a2 !important"
                            class="bg-secondary-light badge font-medium font-12"
                          >
                            Accepted
                          </p>
                        </div>
                      </div>

                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop estimate
                              details</span
                            >
                          </div>
                          <a
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="
                              downloadFile(
                                estimate_status == 'ACCEPTED' ? estimate : revice_estimate
                              )
                            "
                            >Download</a
                          >
                        </div>
                      </div>
                      <div class="row mt-5">
                        <div class="col-lg-5">
                          <h3>Material Cost</h3>
                        </div>
                        <div class="col-lg-7">
                          <input
                            :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                            type="text"
                            class="form-control mb-3"
                            placeholder="Enter amount incl GST"
                            id=""
                            v-if="revice_estimate"
                            v-model="material_cost_new_estimate"
                          />
                          <input
                            :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                            type="text"
                            class="form-control mb-3"
                            placeholder="Enter amount incl GST"
                            id=""
                            v-if="!revice_estimate"
                            v-model="material_cost_old_estimate"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-5">
                          <h3>Labour Cost</h3>
                        </div>
                        <div class="col-lg-7">
                          <input
                            :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                            type="text"
                            class="form-control mb-3"
                            placeholder="Enter amount incl GST"
                            id=""
                            v-if="revice_estimate"
                            v-model="labour_cost_new_estimate"
                          />
                          <input
                            :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                            type="text"
                            class="form-control mb-3"
                            placeholder="Enter amount incl GST"
                            id=""
                            v-if="!revice_estimate"
                            v-model="labour_cost_old_estimate"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="revice_estimate">
                    <div class="justify-content-between mb-3">
                      <div class="row">
                        <div class="col-lg-6">
                          <p class="font-bold mb-2">Estimates</p>
                        </div>
                        <div class="col-lg-6">
                          <span
                            style="float: right"
                            class="bg-warning badge font-medium font-12"
                            v-if="
                              estimate_status == 'PENDING' &&
                              revice_estimate_status == 'PENDING'
                            "
                            >Sent</span
                          >
                        </div>
                      </div>
                    </div>
                    <p
                      class="text-secondary"
                      v-if="
                        estimate_status == 'PENDING' ||
                        revice_estimate_status == 'PENDING'
                      "
                    >
                      Note: estimate sent but waiting for acceptance
                    </p>
                    <ul
                      class="nav nav-pills mb-3 tab-style-1"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link active"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          Old Estimate
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          Revised Estimates
                        </button>
                      </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                        tabindex="0"
                      >
                        <span
                          class="bg-success badge font-medium font-12 mb-2"
                          v-if="estimate_status != 'PENDING'"
                          >Accepted</span
                        >
                        <div class="card p-3 rounded-4 bg-secondary-light">
                          <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex gap-3 n">
                              <img
                                src="/static/images/icon-file-color.svg"
                                width="20"
                                height="20"
                              /><span
                                >{{ orderDetails.workshop.workshop_name }} Workshop
                                periodic service details</span
                              >
                            </div>
                            <a
                              class="cursor-pointer text-brand font-bold"
                              v-on:click="downloadFile(this.estimate)"
                              >Download</a
                            >
                          </div>
                        </div>

                        <div class="row mt-5">
                          <div class="col-lg-5">
                            <h3>Material Cost</h3>
                          </div>
                          <div class="col-lg-7">
                            <input
                              :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                              type="text"
                              class="form-control mb-3"
                              placeholder="Enter amount incl GST"
                              id=""
                              v-model="material_cost_old_estimate"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-5">
                            <h3>Labour Cost</h3>
                          </div>
                          <div class="col-lg-7">
                            <input
                              :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                              type="text"
                              class="form-control mb-3"
                              placeholder="Enter amount incl GST"
                              id=""
                              v-model="labour_cost_old_estimate"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                        tabindex="0"
                      >
                        <span
                          class="bg-success badge font-medium font-12 mb-2"
                          v-if="revice_estimate_status != 'PENDING'"
                          >Accepted</span
                        >
                        <div class="card p-3 rounded-4 bg-secondary-light">
                          <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex gap-3 n">
                              <img
                                src="/static/images/icon-file-color.svg"
                                width="20"
                                height="20"
                              /><span
                                >{{ orderDetails.workshop.workshop_name }} Workshop
                                periodic service details</span
                              >
                            </div>
                            <a
                              class="cursor-pointer text-brand font-bold"
                              v-on:click="downloadFile(this.revice_estimate)"
                              >Download</a
                            >
                          </div>
                        </div>

                        <div
                          class="row mt-5"
                          v-if="
                            orderDetails.is_revice_estimate == 'N' &&
                            orderDetails.order_status == 'REVICE_ESTIMATE'
                          "
                        >
                          <div class="col-lg-5">
                            <h3>Material Cost</h3>
                          </div>
                          <div class="col-lg-7">
                            <input
                              :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                              type="text"
                              class="form-control mb-3"
                              placeholder="Enter amount incl GST"
                              id=""
                              v-model="material_cost_new_estimate"
                            />
                          </div>
                        </div>
                        <div
                          class="row"
                          v-if="
                            orderDetails.is_revice_estimate == 'N' &&
                            orderDetails.order_status == 'REVICE_ESTIMATE'
                          "
                        >
                          <div class="col-lg-5">
                            <h3>Labour Cost</h3>
                          </div>
                          <div class="col-lg-7">
                            <input
                              :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                              type="text"
                              class="form-control mb-3"
                              placeholder="Enter amount incl GST"
                              id=""
                              v-model="labour_cost_new_estimate"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="pb-3" v-if="estimate">
                      <div class="row">
                        <div class="col-lg-6">
                          <p class="font-bold mb-2">Estimates</p>
                        </div>
                        <div class="col-lg-6">
                          <p
                            style="float: right"
                            class="bg-warning badge font-medium font-12"
                            v-if="
                              estimate_status == 'PENDING' &&
                              revice_estimate_status == 'PENDING'
                            "
                          >
                            <span
                              v-if="
                                orderDetails.is_revice_estimate == 'N' &&
                                orderDetails.order_status == 'GENERATE_ESTIMATE'
                              "
                              >Sent</span
                            >
                            <span
                              v-if="
                                orderDetails.is_revice_estimate == 'Y' &&
                                orderDetails.order_status == 'REVICE_ESTIMATE'
                              "
                              >Revision Requested</span
                            >
                            <span
                              v-if="
                                orderDetails.is_revice_estimate == 'N' &&
                                orderDetails.order_status == 'REVICE_ESTIMATE'
                              "
                              >Revision Sent</span
                            >
                          </p>
                        </div>
                      </div>
                      <p
                        class="text-secondary"
                        v-if="
                          estimate_status == 'PENDING' ||
                          revice_estimate_status == 'PENDING'
                        "
                      >
                        Note: estimate sent but waiting for acceptance
                      </p>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop estimate
                              details</span
                            >
                          </div>
                          <a
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(this.estimate)"
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        orderDetails.is_revice_estimate == 'N' &&
                        orderDetails.order_status == 'GENERATE_ESTIMATE'
                      "
                    >
                      <div class="col-lg-5">
                        <h3>Material Cost</h3>
                      </div>
                      <div class="col-lg-7">
                        <input
                          :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                          type="text"
                          class="form-control mb-3"
                          placeholder="Enter amount incl GST"
                          id=""
                          v-model="material_cost_old_estimate"
                        />
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        orderDetails.is_revice_estimate == 'N' &&
                        orderDetails.order_status == 'GENERATE_ESTIMATE'
                      "
                    >
                      <div class="col-lg-5">
                        <h3>Labour Cost</h3>
                      </div>
                      <div class="col-lg-7">
                        <input
                          :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                          type="text"
                          class="form-control mb-3"
                          placeholder="Enter amount incl GST"
                          id=""
                          v-model="labour_cost_old_estimate"
                        />
                      </div>
                    </div>
                  </template>
                </div>
                <template
                  v-if="
                    orderDetails.order_status == 'IN_PROGRESS' ||
                    orderDetails.estimate_type == 'IMAGE'
                  "
                >
                  <p
                    v-if="
                      orderDetails.order_status == 'IN_PROGRESS' ||
                      (orderDetails.is_revice_estimate == 'Y' &&
                        orderDetails.order_status == 'REVICE_ESTIMATE')
                    "
                  >
                    Upload Estimate
                  </p>
                  <div
                    class="upload-btn-container mb-4"
                    v-if="
                      (!flow_file || (flow_file && fileType == 'PDF')) &&
                      (orderDetails.order_status == 'IN_PROGRESS' ||
                        (orderDetails.is_revice_estimate == 'Y' &&
                          orderDetails.order_status == 'REVICE_ESTIMATE'))
                    "
                  >
                    <input
                      type="file"
                      accept="image/*"
                      @change="uploadEstimateDocument($event)"
                    />
                    <label for="preview-pan-0">
                      <div class="py-4 text-center">
                        <div class="mb-3">
                          <img
                            src="static/images/icon-file.svg"
                            width="20"
                            class="mx-auto"
                            alt=""
                          />
                        </div>
                        <div class="mb-3 text-secondary">Select document to upload</div>
                        <div>
                          <button class="btn btn-primary">Upload File</button>
                        </div>
                      </div>
                    </label>
                    <div class="text-center">
                      <span class="font-12 text-secondary"
                        >Supported files: Jpeg, PNG (Max. file size 4 MB)</span
                      >
                    </div>
                  </div>
                  <div
                    v-if="
                      flow_file &&
                      image &&
                      (orderDetails.order_status == 'IN_PROGRESS' ||
                        (orderDetails.is_revice_estimate == 'Y' &&
                          orderDetails.order_status == 'REVICE_ESTIMATE'))
                    "
                    class="upload-btn-container mb-4"
                  >
                    <img
                      :src="image ? image : 'static/images/default.png'"
                      width="100"
                      height="700"
                      alt="Uploaded Cheque Image"
                      style="width: 100% !important; height: 100% !important"
                    />
                    <div class="text-center" style="margin: 10px !important">
                      <button class="btn btn-primary" v-on:click="removeFile()">
                        Remove
                      </button>
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="
                      (is_estimate == 'N' && orderDetails.order_status != 'PENDING') ||
                      (orderDetails.order_status == 'REVICE_ESTIMATE' &&
                        orderDetails.is_revice_estimate == 'Y')
                    "
                  >
                    <div class="col-lg-5">
                      <h3>Material Cost</h3>
                    </div>
                    <div class="col-lg-7">
                      <input
                        :disabled="
                          orderDetails.order_status != 'IN_PROGRESS' &&
                          orderDetails.order_status != 'REVICE_ESTIMATE'
                        "
                        type="text"
                        class="form-control mb-3"
                        placeholder="Enter amount incl GST"
                        id=""
                        v-model="orderDetails.material_cost_image"
                      />
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="
                      (is_estimate == 'N' && orderDetails.order_status != 'PENDING') ||
                      (orderDetails.order_status == 'REVICE_ESTIMATE' &&
                        orderDetails.is_revice_estimate == 'Y')
                    "
                  >
                    <div class="col-lg-5">
                      <h3>Labour Cost</h3>
                    </div>
                    <div class="col-lg-7">
                      <input
                        :disabled="
                          orderDetails.order_status != 'IN_PROGRESS' &&
                          orderDetails.order_status != 'REVICE_ESTIMATE'
                        "
                        type="text"
                        class="form-control mb-3"
                        placeholder="Enter amount incl GST"
                        id=""
                        v-model="orderDetails.labour_cost_image"
                      />
                    </div>
                  </div>

                  <button
                    v-if="
                      revice_estimate_status != 'ACCEPTED' &&
                      orderDetails &&
                      orderDetails.order_status == 'IN_PROGRESS'
                    "
                    class="btn btn-primary px-5 mt-3"
                    id="estimate-cost-payment"
                    :disabled="!flow_file || !fileType || fileType == 'PDF'"
                    v-on:click="
                      sendMaterialCost('GENERATE_ESTIMATE', 'estimate-cost-payment')
                    "
                  >
                    Send
                  </button>
                  <button
                    v-if="
                      orderDetails &&
                      orderDetails.is_revice_estimate == 'Y' &&
                      revice_estimate_status != 'ACCEPTED' &&
                      orderDetails.order_status == 'REVICE_ESTIMATE'
                    "
                    class="btn btn-primary px-5 mt-3"
                    id="estimate-cost-payment1"
                    :disabled="!flow_file || !fileType || fileType == 'PDF'"
                    v-on:click="
                      sendMaterialCost('REVICE_ESTIMATE', 'estimate-cost-payment1')
                    "
                  >
                    Send
                  </button>
                </template>

                <div
                  class="mb-3 border-bottom pb-3"
                  v-if="is_estimate == 'Y' && orderDetails.estimate_type == 'WEB'"
                >
                  <template
                    v-if="
                      estimate_status == 'ACCEPTED' ||
                      revice_estimate_status == 'ACCEPTED'
                    "
                  >
                    <div class="pb-3">
                      <p class="font-bold mb-2">Estimate</p>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop estimate
                              details</span
                            >
                          </div>
                          <a
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="
                              downloadFile(
                                estimate_status == 'ACCEPTED' ? estimate : revice_estimate
                              )
                            "
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="orderDetails.estimate_send_count > 2">
                    <div class="justify-content-between mb-3">
                      <div class="row">
                        <div class="col-lg-6">
                          <p class="font-bold mb-2">Estimate</p>
                        </div>
                        <div class="col-lg-6">
                          <p
                            style="float: right"
                            class="bg-warning badge font-medium font-12"
                            v-if="
                              estimate_status == 'PENDING' &&
                              revice_estimate_status == 'PENDING'
                            "
                          >
                            <span
                              v-if="
                                orderDetails.is_revice_estimate == 'N' &&
                                orderDetails.order_status == 'IN_PROGRESS'
                              "
                              >Sent</span
                            >
                            <span
                              v-if="
                                orderDetails.is_revice_estimate == 'Y' &&
                                orderDetails.order_status == 'REVICE_ESTIMATE'
                              "
                              >Revision Requested</span
                            >
                            <span
                              v-if="
                                orderDetails.is_revice_estimate == 'N' &&
                                orderDetails.order_status == 'REVICE_ESTIMATE'
                              "
                              >Revision Sent</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                    <p
                      class="text-secondary"
                      v-if="
                        estimate_status == 'PENDING' ||
                        revice_estimate_status == 'PENDING'
                      "
                    >
                      Note: estimate sent but waiting for acceptance
                    </p>
                    <ul
                      class="nav nav-pills mb-3 tab-style-1"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link active"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          Old Estimate
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          Revised Estimates
                        </button>
                      </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                        tabindex="0"
                      >
                        <span
                          class="bg-success badge font-medium font-12 mb-2"
                          v-if="estimate_status != 'PENDING'"
                          >Accepted</span
                        >
                        <div class="card p-3 rounded-4 bg-secondary-light">
                          <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex gap-3 n">
                              <img
                                src="/static/images/icon-file-color.svg"
                                width="20"
                                height="20"
                              /><span
                                >{{ orderDetails.workshop.workshop_name }} Workshop
                                periodic service details</span
                              >
                            </div>
                            <a
                              class="cursor-pointer text-brand font-bold"
                              v-on:click="downloadFile(this.estimate)"
                              >Download</a
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                        tabindex="0"
                      >
                        <span
                          class="bg-success badge font-medium font-12 mb-2"
                          v-if="revice_estimate_status != 'PENDING'"
                          >Accepted</span
                        >
                        <div class="card p-3 rounded-4 bg-secondary-light">
                          <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex gap-3 n">
                              <img
                                src="/static/images/icon-file-color.svg"
                                width="20"
                                height="20"
                              /><span
                                >{{ orderDetails.workshop.workshop_name }} Workshop
                                periodic service details</span
                              >
                            </div>
                            <a
                              class="cursor-pointer text-brand font-bold"
                              v-on:click="downloadFile(this.revice_estimate)"
                              >Download</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="pb-3" v-if="estimate">
                      <div class="row">
                        <div class="col-lg-6">
                          <p class="font-bold mb-2">Estimate</p>
                        </div>
                        <div class="col-lg-6">
                          <p
                            style="float: right"
                            class="bg-warning badge font-medium font-12"
                            v-if="
                              estimate_status == 'PENDING' &&
                              revice_estimate_status == 'PENDING'
                            "
                          >
                            <span
                              v-if="
                                orderDetails.is_revice_estimate == 'N' &&
                                orderDetails.order_status == 'GENERATE_ESTIMATE'
                              "
                              >Sent</span
                            >
                            <span
                              v-if="
                                orderDetails.is_revice_estimate == 'Y' &&
                                orderDetails.order_status == 'REVICE_ESTIMATE'
                              "
                              >Revision Requested</span
                            >
                            <span
                              v-if="
                                orderDetails.is_revice_estimate == 'N' &&
                                orderDetails.order_status == 'REVICE_ESTIMATE'
                              "
                              >Revision Sent</span
                            >
                          </p>
                        </div>
                      </div>
                      <p class="text-secondary">
                        <span
                          v-if="
                            orderDetails.order_status == 'GENERATE_ESTIMATE' ||
                            (orderDetails.is_revice_estimate == 'N' &&
                              orderDetails.order_status == 'REVICE_ESTIMATE')
                          "
                          >Note: estimate sent but waiting for acceptance</span
                        >
                      </p>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop estimate
                              details</span
                            >
                          </div>
                          <a
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(this.estimate)"
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </div>

                <div
                  class="m-3 text-center"
                  v-if="orderDetails.order_status == 'IN_PROGRESS'"
                >
                  <h3>Or</h3>
                </div>

                <div
                  class="mb-3 border-bottom pb-3"
                  v-if="is_estimate == 'Y' && orderDetails.estimate_type == 'PDF'"
                >
                  <template
                    v-if="
                      estimate_status == 'ACCEPTED' ||
                      revice_estimate_status == 'ACCEPTED'
                    "
                  >
                    <div class="pb-3">
                      <div class="row">
                        <div class="col-lg-6">
                          <p class="font-bold mb-2">Estimate</p>
                        </div>
                        <div class="col-lg-6">
                          <p
                            style="float: right; background-color: #3e96a2 !important"
                            class="bg-secondary-light badge font-medium font-12"
                          >
                            Accepted
                          </p>
                        </div>
                      </div>

                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop estimate
                              details</span
                            >
                          </div>
                          <a
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="
                              downloadFile(
                                estimate_status == 'ACCEPTED' ? estimate : revice_estimate
                              )
                            "
                            >Download</a
                          >
                        </div>
                      </div>
                      <div class="row mt-5">
                        <div class="col-lg-5">
                          <h3>Material Cost</h3>
                        </div>
                        <div class="col-lg-7">
                          <input
                            :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                            type="text"
                            class="form-control mb-3"
                            placeholder="Enter amount incl GST"
                            id=""
                            v-model="material_cost_old_estimate"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-5">
                          <h3>Labour Cost</h3>
                        </div>
                        <div class="col-lg-7">
                          <input
                            :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                            type="text"
                            class="form-control mb-3"
                            placeholder="Enter amount incl GST"
                            id=""
                            v-model="labour_cost_old_estimate"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="revice_estimate">
                    <div class="justify-content-between mb-3">
                      <div class="row">
                        <div class="col-lg-6">
                          <p class="font-bold mb-2">Estimates</p>
                        </div>
                        <div class="col-lg-6">
                          <span
                            style="float: right"
                            class="bg-warning badge font-medium font-12"
                            v-if="
                              estimate_status == 'PENDING' &&
                              revice_estimate_status == 'PENDING'
                            "
                            >Sent</span
                          >
                        </div>
                      </div>
                    </div>
                    <p
                      class="text-secondary"
                      v-if="
                        estimate_status == 'PENDING' ||
                        revice_estimate_status == 'PENDING'
                      "
                    >
                      Note: estimate sent but waiting for acceptance
                    </p>
                    <ul
                      class="nav nav-pills mb-3 tab-style-1"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link active"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          Old Estimate
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          Revised Estimates
                        </button>
                      </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                        tabindex="0"
                      >
                        <span
                          class="bg-success badge font-medium font-12 mb-2"
                          v-if="estimate_status != 'PENDING'"
                          >Accepted</span
                        >
                        <div class="card p-3 rounded-4 bg-secondary-light">
                          <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex gap-3 n">
                              <img
                                src="/static/images/icon-file-color.svg"
                                width="20"
                                height="20"
                              /><span
                                >{{ orderDetails.workshop.workshop_name }} Workshop
                                periodic service details</span
                              >
                            </div>
                            <a
                              class="cursor-pointer text-brand font-bold"
                              v-on:click="downloadFile(this.estimate)"
                              >Download</a
                            >
                          </div>
                        </div>

                        <div class="row mt-5">
                          <div class="col-lg-5">
                            <h3>Material Cost</h3>
                          </div>
                          <div class="col-lg-7">
                            <input
                              :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                              type="text"
                              class="form-control mb-3"
                              placeholder="Enter amount incl GST"
                              id=""
                              v-model="material_cost_old_estimate"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-5">
                            <h3>Labour Cost</h3>
                          </div>
                          <div class="col-lg-7">
                            <input
                              :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                              type="text"
                              class="form-control mb-3"
                              placeholder="Enter amount incl GST"
                              id=""
                              v-model="labour_cost_old_estimate"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                        tabindex="0"
                      >
                        <span
                          class="bg-success badge font-medium font-12 mb-2"
                          v-if="revice_estimate_status != 'PENDING'"
                          >Accepted</span
                        >
                        <div class="card p-3 rounded-4 bg-secondary-light">
                          <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex gap-3 n">
                              <img
                                src="/static/images/icon-file-color.svg"
                                width="20"
                                height="20"
                              /><span
                                >{{ orderDetails.workshop.workshop_name }} Workshop
                                periodic service details</span
                              >
                            </div>
                            <a
                              class="cursor-pointer text-brand font-bold"
                              v-on:click="downloadFile(this.revice_estimate)"
                              >Download</a
                            >
                          </div>
                        </div>

                        <div
                          class="row mt-5"
                          v-if="
                            orderDetails.is_revice_estimate == 'N' &&
                            orderDetails.order_status == 'REVICE_ESTIMATE'
                          "
                        >
                          <div class="col-lg-5">
                            <h3>Material Cost</h3>
                          </div>
                          <div class="col-lg-7">
                            <input
                              :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                              type="text"
                              class="form-control mb-3"
                              placeholder="Enter amount incl GST"
                              id=""
                              v-model="material_cost_new_estimate"
                            />
                          </div>
                        </div>
                        <div
                          class="row"
                          v-if="
                            orderDetails.is_revice_estimate == 'N' &&
                            orderDetails.order_status == 'REVICE_ESTIMATE'
                          "
                        >
                          <div class="col-lg-5">
                            <h3>Labour Cost</h3>
                          </div>
                          <div class="col-lg-7">
                            <input
                              :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                              type="text"
                              class="form-control mb-3"
                              placeholder="Enter amount incl GST"
                              id=""
                              v-model="labour_cost_new_estimate"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="pb-3" v-if="estimate">
                      <div class="row">
                        <div class="col-lg-6">
                          <p class="font-bold mb-2">Estimates</p>
                        </div>
                        <div class="col-lg-6">
                          <p
                            style="float: right"
                            class="bg-warning badge font-medium font-12"
                            v-if="
                              estimate_status == 'PENDING' &&
                              revice_estimate_status == 'PENDING'
                            "
                          >
                            <span
                              v-if="
                                orderDetails.is_revice_estimate == 'N' &&
                                orderDetails.order_status == 'GENERATE_ESTIMATE'
                              "
                              >Sent</span
                            >
                            <span
                              v-if="
                                orderDetails.is_revice_estimate == 'Y' &&
                                orderDetails.order_status == 'REVICE_ESTIMATE'
                              "
                              >Revision Requested</span
                            >
                            <span
                              v-if="
                                orderDetails.is_revice_estimate == 'N' &&
                                orderDetails.order_status == 'REVICE_ESTIMATE'
                              "
                              >Revision Sent</span
                            >
                          </p>
                        </div>
                      </div>
                      <p
                        class="text-secondary"
                        v-if="
                          estimate_status == 'PENDING' ||
                          revice_estimate_status == 'PENDING'
                        "
                      >
                        Note: estimate sent but waiting for acceptance
                      </p>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop estimate
                              details</span
                            >
                          </div>
                          <a
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(this.estimate)"
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        orderDetails.is_revice_estimate == 'N' &&
                        orderDetails.order_status == 'GENERATE_ESTIMATE'
                      "
                    >
                      <div class="col-lg-5">
                        <h3>Material Cost</h3>
                      </div>
                      <div class="col-lg-7">
                        <input
                          :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                          type="text"
                          class="form-control mb-3"
                          placeholder="Enter amount incl GST"
                          id=""
                          v-model="material_cost_old_estimate"
                        />
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        orderDetails.is_revice_estimate == 'N' &&
                        orderDetails.order_status == 'GENERATE_ESTIMATE'
                      "
                    >
                      <div class="col-lg-5">
                        <h3>Labour Cost</h3>
                      </div>
                      <div class="col-lg-7">
                        <input
                          :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                          type="text"
                          class="form-control mb-3"
                          placeholder="Enter amount incl GST"
                          id=""
                          v-model="labour_cost_old_estimate"
                        />
                      </div>
                    </div>
                  </template>
                </div>
                <template
                  v-if="
                    orderDetails.order_status == 'IN_PROGRESS' ||
                    orderDetails.estimate_type == 'PDF'
                  "
                >
                  <p
                    v-if="
                      orderDetails.order_status == 'IN_PROGRESS' ||
                      (orderDetails.is_revice_estimate == 'Y' &&
                        orderDetails.order_status == 'REVICE_ESTIMATE')
                    "
                  >
                    Upload Document
                  </p>
                  <div
                    class="upload-btn-container mb-4"
                    v-if="
                      (!flow_file || (flow_file && fileType == 'IMAGE')) &&
                      (orderDetails.order_status == 'IN_PROGRESS' ||
                        (orderDetails.is_revice_estimate == 'Y' &&
                          orderDetails.order_status == 'REVICE_ESTIMATE'))
                    "
                  >
                    <input
                      type="file"
                      accept="application/pdf"
                      @change="uploadEstimatePdfDocument($event)"
                    />
                    <label for="preview-pan-0">
                      <div class="py-4 text-center">
                        <div class="mb-3">
                          <img
                            src="static/images/icon-file.svg"
                            width="20"
                            class="mx-auto"
                            alt=""
                          />
                        </div>
                        <div class="mb-3 text-secondary">Select document to upload</div>
                        <div>
                          <button class="btn btn-primary">Upload File</button>
                        </div>
                      </div>
                    </label>
                    <div class="text-center">
                      <span class="font-12 text-secondary"
                        >Supported files: PDF (Max. file size 4 MB)</span
                      >
                    </div>
                  </div>
                  <div
                    v-if="
                      flow_file &&
                      fileType &&
                      fileType == 'PDF' &&
                      (orderDetails.order_status == 'IN_PROGRESS' ||
                        (orderDetails.is_revice_estimate == 'Y' &&
                          orderDetails.order_status == 'REVICE_ESTIMATE'))
                    "
                    class="upload-btn-container mb-4 text-center"
                  >
                    <img
                      src="/static/images/pdf.png"
                      height="700"
                      alt="Uploaded Cheque Image"
                      style="width: 15% !important"
                    />
                    <div class="text-center" style="margin: 10px !important">
                      <button class="btn btn-primary" v-on:click="removeFile()">
                        Remove
                      </button>
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="
                      (is_estimate == 'N' && orderDetails.order_status != 'PENDING') ||
                      (orderDetails.order_status == 'REVICE_ESTIMATE' &&
                        orderDetails.is_revice_estimate == 'Y')
                    "
                  >
                    <div class="col-lg-5">
                      <h3>Material Cost</h3>
                    </div>
                    <div class="col-lg-7">
                      <input
                        :disabled="
                          orderDetails.order_status != 'IN_PROGRESS' &&
                          orderDetails.order_status != 'REVICE_ESTIMATE'
                        "
                        type="text"
                        class="form-control mb-3"
                        placeholder="Enter amount incl GST"
                        id=""
                        v-model="orderDetails.material_cost"
                      />
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="
                      (is_estimate == 'N' && orderDetails.order_status != 'PENDING') ||
                      (orderDetails.order_status == 'REVICE_ESTIMATE' &&
                        orderDetails.is_revice_estimate == 'Y')
                    "
                  >
                    <div class="col-lg-5">
                      <h3>Labour Cost</h3>
                    </div>
                    <div class="col-lg-7">
                      <input
                        :disabled="
                          orderDetails.order_status != 'IN_PROGRESS' &&
                          orderDetails.order_status != 'REVICE_ESTIMATE'
                        "
                        type="text"
                        class="form-control mb-3"
                        placeholder="Enter amount incl GST"
                        id=""
                        v-model="orderDetails.labour_cost"
                      />
                    </div>
                  </div>

                  <button
                    v-if="
                      revice_estimate_status != 'ACCEPTED' &&
                      orderDetails &&
                      orderDetails.order_status == 'IN_PROGRESS'
                    "
                    class="btn btn-primary px-5 mt-3"
                    id="estimate-cost-payment2"
                    :disabled="!flow_file || !fileType || fileType == 'IMAGE'"
                    v-on:click="
                      sendMaterialCost('GENERATE_ESTIMATE', 'estimate-cost-payment2')
                    "
                  >
                    Send
                  </button>
                  <button
                    v-if="
                      orderDetails &&
                      orderDetails.is_revice_estimate == 'Y' &&
                      revice_estimate_status != 'ACCEPTED' &&
                      orderDetails.order_status == 'REVICE_ESTIMATE'
                    "
                    class="btn btn-primary px-5 mt-3"
                    id="estimate-cost-payment3"
                    :disabled="!flow_file || !fileType || fileType == 'IMAGE'"
                    v-on:click="
                      sendMaterialCost('REVICE_ESTIMATE', 'estimate-cost-payment3')
                    "
                  >
                    Send
                  </button>
                </template>
              </div>

              <div
                class="card p-4 mb-4"
                v-if="
                  orderDetails.order_status != 'DELIVERED' &&
                  orderDetails.order_status != 'ESTIMATE_ACCEPTED' &&
                  (estimate_status == 'ACCEPTED' || revice_estimate_status == 'ACCEPTED')
                "
              >
                <div
                  class="mb-3 border-bottom pb-3"
                  v-if="proforma && orderDetails.order_status != 'DELIVERED'"
                >
                  <template
                    v-if="
                      proforma_status == 'ACCEPTED' ||
                      revice_proforma_status == 'ACCEPTED'
                    "
                  >
                    <div class="pb-3">
                      <p class="font-bold mb-2">Proforma</p>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop proforma
                              details</span
                            >
                          </div>
                          <a
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="
                              downloadFile(
                                proforma_status == 'ACCEPTED' ? proforma : revice_proforma
                              )
                            "
                            >Download</a
                          >
                        </div>
                      </div>
                      <div class="row mt-5">
                        <div class="col-lg-5">
                          <h3>Material Cost</h3>
                        </div>
                        <div class="col-lg-7">
                          <input
                            :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                            type="text"
                            class="form-control mb-3"
                            placeholder="Enter amount incl GST"
                            id=""
                            v-if="orderDetails.estimate_type == 'IMAGE'"
                            v-model="orderDetails.material_cost_image"
                          />
                          <input
                            :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                            type="text"
                            class="form-control mb-3"
                            placeholder="Enter amount incl GST"
                            id=""
                            v-if="orderDetails.estimate_type == 'PDF'"
                            v-model="orderDetails.material_cost"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-5">
                          <h3>Labour Cost</h3>
                        </div>
                        <div class="col-lg-7">
                          <input
                            :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                            type="text"
                            class="form-control mb-3"
                            placeholder="Enter amount incl GST"
                            id=""
                            v-if="orderDetails.estimate_type == 'IMAGE'"
                            v-model="orderDetails.labour_cost_image"
                          />
                          <input
                            :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                            type="text"
                            class="form-control mb-3"
                            placeholder="Enter amount incl GST"
                            id=""
                            v-if="orderDetails.estimate_type == 'PDF'"
                            v-model="orderDetails.labour_cost"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="pb-3" v-if="proforma">
                      <div class="row">
                        <div class="col-lg-6">
                          <p class="font-bold mb-2">Proforma</p>
                        </div>
                        <div class="col-lg-6">
                          <p
                            style="float: right"
                            class="bg-warning badge font-medium font-12"
                            v-if="
                              proforma_status == 'PENDING' &&
                              revice_proforma_status == 'PENDING'
                            "
                          >
                            <span
                              v-if="
                                orderDetails.is_revice_proforma == 'N' &&
                                orderDetails.order_status == 'COMPLETED'
                              "
                              >Sent</span
                            >
                            <span
                              v-if="
                                orderDetails.is_revice_proforma == 'Y' &&
                                orderDetails.order_status == 'COMPLETED'
                              "
                              >Revision Requested</span
                            >
                          </p>
                        </div>
                      </div>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop proforma
                              details</span
                            >
                          </div>
                          <a
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(this.proforma)"
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        orderDetails.order_status == 'COMPLETED' &&
                        (orderDetails.estimate_type == 'PDF' ||
                          orderDetails.estimate_type == 'IMAGE')
                      "
                    >
                      <div class="col-lg-5">
                        <h3>Material Cost</h3>
                      </div>
                      <div class="col-lg-7">
                        <input
                          :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                          type="text"
                          class="form-control mb-3"
                          placeholder="Enter amount incl GST"
                          id=""
                          v-model="material_cost_old_profoma"
                        />
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        orderDetails.order_status == 'COMPLETED' &&
                        (orderDetails.estimate_type == 'PDF' ||
                          orderDetails.estimate_type == 'IMAGE')
                      "
                    >
                      <div class="col-lg-5">
                        <h3>Labour Cost</h3>
                      </div>
                      <div class="col-lg-7">
                        <input
                          :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                          type="text"
                          class="form-control mb-3"
                          placeholder="Enter amount incl GST"
                          id=""
                          v-model="labour_cost_old_profoma"
                        />
                      </div>
                    </div>
                  </template>
                </div>
                <template
                  v-if="
                    orderDetails.estimate_type == 'PDF' ||
                    orderDetails.estimate_type == 'IMAGE'
                  "
                >
                  <div
                    class="upload-btn-container mb-4"
                    v-if="
                      (orderDetails.order_status != 'DELIVERED' &&
                        orderDetails.order_status == 'WORK_START') ||
                      (orderDetails.is_revice_proforma == 'Y' &&
                        orderDetails.order_status == 'COMPLETED')
                    "
                  >
                    <p
                      class="font-bold mb-2"
                      v-if="orderDetails.order_status == 'WORK_START'"
                    >
                      Proforma
                    </p>
                    <p
                      style="float: right"
                      class="bg-warning badge font-medium font-12"
                      v-if="orderDetails.order_status == 'WORK_START'"
                    >
                      <span
                        v-if="
                          orderDetails.is_revice_proforma == 'N' &&
                          orderDetails.order_status == 'PROFORMA'
                        "
                        >Sent</span
                      >
                      <span
                        v-if="
                          orderDetails.is_revice_proforma == 'Y' &&
                          orderDetails.order_status == 'COMPLETED'
                        "
                        >Revision Requested</span
                      >
                      <span
                        v-if="
                          orderDetails.is_revice_proforma == 'N' &&
                          orderDetails.order_status == 'COMPLETED'
                        "
                        >Revision Sent</span
                      >
                    </p>
                    <template v-if="!flow_file">
                      <input
                        v-if="orderDetails.estimate_type == 'IMAGE'"
                        type="file"
                        accept="image/*"
                        @change="uploadEstimateDocument($event)"
                      />
                      <input
                        v-if="orderDetails.estimate_type == 'PDF'"
                        type="file"
                        accept="application/pdf"
                        @change="uploadEstimatePdfDocument($event)"
                      />
                      <label for="preview-pan-0">
                        <div class="py-4 text-center">
                          <div class="mb-3">
                            <img
                              src="static/images/icon-file.svg"
                              width="20"
                              class="mx-auto"
                              alt=""
                            />
                          </div>
                          <div class="mb-3 text-secondary">Select document to upload</div>
                          <div>
                            <button class="btn btn-primary">Upload File</button>
                          </div>
                        </div>
                      </label>
                      <div class="text-center">
                        <span
                          class="font-12 text-secondary"
                          v-if="orderDetails.estimate_type == 'IMAGE'"
                          >Supported files: Jpeg, PNG (Max. file size 4 MB)</span
                        >
                        <span
                          class="font-12 text-secondary"
                          v-if="orderDetails.estimate_type == 'PDF'"
                          >Supported files: PDF (Max. file size 4 MB)</span
                        >
                      </div>
                    </template>
                    <template
                      v-if="flow_file && image && orderDetails.estimate_type == 'IMAGE'"
                    >
                      <div class="upload-btn-container mb-4">
                        <img
                          :src="image ? image : 'static/images/default.png'"
                          width="100"
                          height="700"
                          alt="Uploaded Cheque Image"
                          style="width: 100% !important; height: 100% !important"
                        />
                        <div class="text-center" style="margin: 10px !important">
                          <button class="btn btn-primary" v-on:click="removeFile()">
                            Remove
                          </button>
                        </div>
                      </div>
                    </template>

                    <template v-if="flow_file && orderDetails.estimate_type == 'PDF'"
                      ><div class="upload-btn-container mb-4 text-center">
                        <img
                          src="/static/images/pdf.png"
                          height="700"
                          alt="Uploaded Cheque Image"
                          style="width: 15% !important"
                        />
                        <div class="text-center" style="margin: 10px !important">
                          <button class="btn btn-primary" v-on:click="removeFile()">
                            Remove
                          </button>
                        </div>
                      </div>
                    </template>
                  </div>

                  <div
                    class="row"
                    v-if="
                      (orderDetails.order_status != 'DELIVERED' && is_proforma == 'N') ||
                      (orderDetails.order_status == 'COMPLETED' &&
                        orderDetails.is_revice_proforma == 'Y')
                    "
                  >
                    <div class="col-lg-5">
                      <h3>Material Cost</h3>
                    </div>
                    <div class="col-lg-7">
                      <input
                        :disabled="
                          (orderDetails.order_status == 'WORK_START' &&
                            orderDetails.is_revice_proforma == 'Y') ||
                          (orderDetails.order_status == 'COMPLETED' &&
                            orderDetails.is_revice_proforma == 'N')
                        "
                        type="text"
                        class="form-control mb-3"
                        placeholder="Enter amount incl GST"
                        id=""
                        v-if="orderDetails.estimate_type == 'IMAGE'"
                        v-model="orderDetails.material_cost_image"
                      />

                      <input
                        :disabled="
                          (orderDetails.order_status == 'WORK_START' &&
                            orderDetails.is_revice_proforma == 'Y') ||
                          (orderDetails.order_status == 'COMPLETED' &&
                            orderDetails.is_revice_proforma == 'N')
                        "
                        type="text"
                        class="form-control mb-3"
                        placeholder="Enter amount incl GST"
                        id=""
                        v-if="orderDetails.estimate_type == 'PDF'"
                        v-model="orderDetails.material_cost"
                      />
                    </div>
                  </div>

                  <div
                    class="row"
                    v-if="
                      (orderDetails.order_status != 'DELIVERED' && is_proforma == 'N') ||
                      (orderDetails.order_status == 'COMPLETED' &&
                        orderDetails.is_revice_proforma == 'Y')
                    "
                  >
                    <div class="col-lg-5">
                      <h3>Labour Cost</h3>
                    </div>
                    <div class="col-lg-7">
                      <input
                        :disabled="
                          (orderDetails.order_status == 'WORK_START' &&
                            orderDetails.is_revice_proforma == 'Y') ||
                          (orderDetails.order_status == 'COMPLETED' &&
                            orderDetails.is_revice_proforma == 'N')
                        "
                        type="text"
                        class="form-control mb-3"
                        placeholder="Enter amount incl GST"
                        id=""
                        v-if="orderDetails.estimate_type == 'IMAGE'"
                        v-model="orderDetails.labour_cost_image"
                      />
                      <input
                        :disabled="
                          (orderDetails.order_status == 'WORK_START' &&
                            orderDetails.is_revice_proforma == 'Y') ||
                          (orderDetails.order_status == 'COMPLETED' &&
                            orderDetails.is_revice_proforma == 'N')
                        "
                        type="text"
                        class="form-control mb-3"
                        placeholder="Enter amount incl GST"
                        id=""
                        v-if="orderDetails.estimate_type == 'PDF'"
                        v-model="orderDetails.labour_cost"
                      />
                    </div>
                  </div>
                  <button
                    v-if="orderDetails && orderDetails.order_status == 'WORK_START'"
                    class="btn btn-primary px-5 mt-3"
                    id="estimate-cost-payment4"
                    :disabled="!flow_file"
                    v-on:click="sendMaterialCost('PROFORMA', 'estimate-cost-payment4')"
                  >
                    Work Complete
                  </button>

                  <button
                    v-if="
                      orderDetails.is_revice_proforma == 'Y' &&
                      orderDetails.order_status == 'COMPLETED'
                    "
                    class="btn btn-primary px-5 mt-3"
                    id="estimate-cost-payment5"
                    :disabled="!flow_file"
                    v-on:click="sendMaterialCost('PROFORMA', 'estimate-cost-payment5')"
                  >
                    Send Revision
                  </button>
                </template>
                <template v-if="orderDetails.estimate_type == 'WEB'">
                  <button
                    v-if="orderDetails && orderDetails.order_status == 'WORK_START'"
                    class="btn btn-primary px-5 mb-2"
                    v-on:click="
                      this.$router.push(
                        '/invoice/' + this.orderDetails.uuid + '/PROFORMA'
                      )
                    "
                  >
                    Create Pro-Forma Invoice
                  </button>
                  <button
                    v-if="
                      is_revice_proforma == 'Y' &&
                      orderDetails.is_material_labour_cost_added == 'N'
                    "
                    class="btn btn-primary px-5 mb-2"
                    v-on:click="
                      this.$router.push(
                        '/invoice/' + this.orderDetails.uuid + '/PROFORMA'
                      )
                    "
                  >
                    Revise Pro-Forma Invoice
                  </button>
                </template>
              </div>
            </template>
            <template v-if="orderDetails.order_flow == 'FLOW_5'">
              <div
                class="card p-4 mb-4"
                v-if="
                  orderDetails.order_status !== 'CANCELLED' &&
                  orderDetails.order_status !== 'PENDING'
                "
              >
                <div class="text-center">
                  <h4 class="font-18">Documents</h4>
                  <p v-if="is_job_card == 'N' && orderDetails.order_status == 'PENDING'">
                    Upload documents
                  </p>
                </div>
                <div class="row justify-content-center">
                  <div class="col-lg-12">
                    <div
                      class="upload-btn-container mb-4"
                      v-if="
                        is_job_card == 'N' &&
                        (orderDetails.order_status == 'WORK_START' ||
                          orderDetails.order_status == 'IN_PROGRESS' ||
                          orderDetails.order_status == 'SEND_COST' ||
                          orderDetails.order_status == 'REVICE_COST' ||
                          orderDetails.order_status == 'COST_ACCEPTED')
                      "
                    >
                      <input
                        type="file"
                        accept="image/jpeg, image/png, image/jpg"
                        @change="uploadDocument($event)"
                      />
                      <label for="preview-pan-0">
                        <div class="py-4 text-center">
                          <div class="mb-3">
                            <img
                              src="static/images/icon-file.svg"
                              width="20"
                              class="mx-auto"
                              alt=""
                            />
                          </div>
                          <div class="mb-3 text-secondary">Select document to upload</div>
                          <div>
                            <button class="btn btn-primary">Upload File</button>
                          </div>
                        </div>
                      </label>
                      <div class="text-center">
                        <span class="font-12 text-secondary"
                          >Supported files: Jpeg, PNG, PDF (Max. file size 4 MB)</span
                        >
                      </div>
                    </div>
                    <input
                      hidden
                      type="file"
                      id="job_card-upload"
                      accept="image/jpeg, image/png, image/jpg"
                      @change="uploadDocument($event)"
                    />

                    <div class="mb-3 border-bottom pb-3" v-if="is_job_card == 'Y'">
                      <p class="font-bold mb-2">Job Card</p>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop periodic
                              service details</span
                            >
                          </div>
                          <a
                            v-if="orderDetails.order_status == 'IN_PROGRESS'"
                            class="cursor-pointer text-brand font-bold"
                            data-bs-toggle="modal"
                            data-bs-target="#DocumentModal"
                            >View</a
                          >
                          <a
                            v-else
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(this.job_card)"
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 border-bottom pb-3" v-if="proforma && final_invoice">
                      <p class="font-bold mb-2">Pro-Forma Invoice</p>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop proforma
                              details</span
                            >
                          </div>
                          <a
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(this.proforma)"
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 border-bottom pb-3" v-if="final_invoice">
                      <p class="font-bold mb-2">Final Invoice</p>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop
                              invoice</span
                            >
                          </div>
                          <a
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(this.final_invoice)"
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="card p-4 mb-4"
                v-if="
                  orderDetails.order_status == 'IN_PROGRESS' ||
                  (is_revice_proforma == 'Y' &&
                    orderDetails.is_material_labour_cost_added == 'N')
                "
              >
                <div class="text-center">
                  <button
                    v-if="orderDetails && orderDetails.order_status == 'IN_PROGRESS'"
                    class="btn btn-primary px-5 mb-2"
                    id="work-start"
                    v-on:click="startWork"
                  >
                    Start Work
                  </button>

                  <button
                    v-if="
                      is_revice_proforma == 'Y' &&
                      orderDetails.is_material_labour_cost_added == 'N'
                    "
                    class="btn btn-primary px-5 mb-2"
                    v-on:click="
                      this.$router.push(
                        '/invoice/' + this.orderDetails.uuid + '/PROFORMA'
                      )
                    "
                  >
                    Revise Pro-Forma Invoice
                  </button>
                </div>
              </div>

              <div
                class="card p-4 mb-4"
                v-if="
                  orderDetails.order_status != 'PENDING' &&
                  orderDetails.order_status != 'IN_PROGRESS' &&
                  orderDetails.order_status != 'DELIVERED' &&
                  orderDetails.order_status != 'ESTIMATE_ACCEPTED'
                "
              >
                <div
                  class="mb-3 border-bottom pb-3"
                  v-if="proforma && orderDetails.order_status != 'DELIVERED'"
                >
                  <template
                    v-if="
                      proforma_status == 'ACCEPTED' ||
                      revice_proforma_status == 'ACCEPTED'
                    "
                  >
                    <div class="pb-3">
                      <p class="font-bold mb-2">Proforma</p>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop proforma
                              details</span
                            >
                          </div>
                          <a
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(proforma)"
                            >Download</a
                          >
                        </div>
                      </div>
                      <div
                        class="row mt-5"
                        v-if="orderDetails.is_material_labour_cost_added == 'Y'"
                      >
                        <div class="col-lg-5">
                          <h3>Material Cost</h3>
                        </div>
                        <div class="col-lg-7">
                          <input
                            :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                            type="text"
                            class="form-control mb-3"
                            placeholder="Enter amount incl GST"
                            id=""
                            v-if="orderDetails.estimate_type == 'IMAGE'"
                            v-model="orderDetails.material_cost_image"
                          />
                          <input
                            :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                            type="text"
                            class="form-control mb-3"
                            placeholder="Enter amount incl GST"
                            id=""
                            v-if="orderDetails.estimate_type == 'PDF'"
                            v-model="orderDetails.material_cost"
                          />
                        </div>
                      </div>
                      <div
                        class="row"
                        v-if="orderDetails.is_material_labour_cost_added == 'Y'"
                      >
                        <div class="col-lg-5">
                          <h3>Labour Cost</h3>
                        </div>
                        <div class="col-lg-7">
                          <input
                            :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                            type="text"
                            class="form-control mb-3"
                            placeholder="Enter amount incl GST"
                            id=""
                            v-if="orderDetails.estimate_type == 'IMAGE'"
                            v-model="orderDetails.labour_cost_image"
                          />
                          <input
                            :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                            type="text"
                            class="form-control mb-3"
                            placeholder="Enter amount incl GST"
                            id=""
                            v-if="orderDetails.estimate_type == 'PDF'"
                            v-model="orderDetails.labour_cost"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="pb-3" v-if="proforma">
                      <div class="row">
                        <div class="col-lg-6">
                          <p class="font-bold mb-2">Proforma</p>
                        </div>
                        <div class="col-lg-6">
                          <p
                            style="float: right"
                            class="bg-warning badge font-medium font-12"
                            v-if="
                              proforma_status == 'PENDING' &&
                              revice_proforma_status == 'PENDING'
                            "
                          >
                            <span
                              v-if="
                                orderDetails.is_revice_proforma == 'N' &&
                                orderDetails.order_status == 'COMPLETED'
                              "
                              >Sent</span
                            >
                            <span
                              v-if="
                                orderDetails.is_revice_proforma == 'Y' &&
                                orderDetails.order_status == 'COMPLETED'
                              "
                              >Revision Requested</span
                            >
                          </p>
                        </div>
                      </div>
                      <div class="card p-3 rounded-4 bg-secondary-light">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex gap-3 n">
                            <img
                              src="/static/images/icon-file-color.svg"
                              width="20"
                              height="20"
                            /><span
                              >{{ orderDetails.workshop.workshop_name }} Workshop proforma
                              details</span
                            >
                          </div>
                          <a
                            class="cursor-pointer text-brand font-bold"
                            v-on:click="downloadFile(this.proforma)"
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        orderDetails.order_status == 'COMPLETED' &&
                        orderDetails.is_material_labour_cost_added == 'Y'
                      "
                    >
                      <div class="col-lg-5">
                        <h3>Material Cost</h3>
                      </div>
                      <div class="col-lg-7">
                        <input
                          :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                          type="text"
                          class="form-control mb-3"
                          placeholder="Enter amount incl GST"
                          id=""
                          v-model="material_cost_old_profoma"
                        />
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        orderDetails.order_status == 'COMPLETED' &&
                        orderDetails.is_material_labour_cost_added == 'Y'
                      "
                    >
                      <div class="col-lg-5">
                        <h3>Labour Cost</h3>
                      </div>
                      <div class="col-lg-7">
                        <input
                          :disabled="orderDetails.order_status != 'IN_PROGRESS'"
                          type="text"
                          class="form-control mb-3"
                          placeholder="Enter amount incl GST"
                          id=""
                          v-model="labour_cost_old_profoma"
                        />
                      </div>
                    </div>
                  </template>
                </div>
                <p
                  v-if="orderDetails && orderDetails.order_status == 'WORK_START'"
                  class="font-bold mb-2"
                >
                  Proforma
                </p>
                <div class="text-center">
                  <button
                    v-if="orderDetails && orderDetails.order_status == 'WORK_START'"
                    class="btn btn-primary px-5 mb-2"
                    v-on:click="
                      this.$router.push(
                        '/invoice/' + this.orderDetails.uuid + '/PROFORMA'
                      )
                    "
                  >
                    Create Pro-Forma Invoice
                  </button>

                  <div
                    class="m-3 text-center"
                    v-if="orderDetails.order_status == 'WORK_START'"
                  >
                    <h3>Or</h3>
                  </div>
                </div>

                <template
                  v-if="
                    orderDetails.order_status == 'WORK_START' ||
                    orderDetails.estimate_type == 'IMAGE'
                  "
                >
                  <div
                    class="upload-btn-container mb-4"
                    v-if="
                      (!flow_file || (flow_file && fileType == 'PDF')) &&
                      (orderDetails.order_status == 'WORK_START' ||
                        orderDetails.order_status == 'COMPLETED') &&
                      ((orderDetails.order_status != 'DELIVERED' &&
                        orderDetails.order_status == 'WORK_START') ||
                        (orderDetails.is_revice_proforma == 'Y' &&
                          orderDetails.order_status == 'COMPLETED'))
                    "
                  >
                    <p
                      class="font-bold mb-2"
                      v-if="
                        orderDetails.order_status == 'WORK_START' ||
                        orderDetails.order_status == 'COMPLETED'
                      "
                    >
                      Upload Proforma
                    </p>
                    <p
                      style="float: right"
                      class="bg-warning badge font-medium font-12"
                      v-if="orderDetails.order_status == 'WORK_START'"
                    >
                      <span
                        v-if="
                          orderDetails.is_revice_proforma == 'N' &&
                          orderDetails.order_status == 'PROFORMA'
                        "
                        >Sent</span
                      >
                      <span
                        v-if="
                          orderDetails.is_revice_proforma == 'Y' &&
                          orderDetails.order_status == 'COMPLETED'
                        "
                        >Revision Requested</span
                      >
                      <span
                        v-if="
                          orderDetails.is_revice_proforma == 'N' &&
                          orderDetails.order_status == 'COMPLETED'
                        "
                        >Revision Sent</span
                      >
                    </p>

                    <input
                      type="file"
                      accept="image/*,application/pdf"
                      @change="uploadEstimateDocument($event)"
                    />
                    <label for="preview-pan-0">
                      <div class="py-4 text-center">
                        <div class="mb-3">
                          <img
                            src="static/images/icon-file.svg"
                            width="20"
                            class="mx-auto"
                            alt=""
                          />
                        </div>
                        <div class="mb-3 text-secondary">Select document to upload</div>
                        <div>
                          <button class="btn btn-primary">Upload File</button>
                        </div>
                      </div>
                    </label>
                    <div class="text-center">
                      <span class="font-12 text-secondary"
                        >Supported files: Jpeg, PNG (Max. file size 4 MB)</span
                      >
                    </div>
                  </div>
                  <div
                    v-if="
                      flow_file &&
                      image &&
                      (orderDetails.order_status == 'WORK_START' ||
                        orderDetails.order_status == 'COMPLETED') &&
                      ((orderDetails.order_status != 'DELIVERED' &&
                        orderDetails.order_status == 'WORK_START') ||
                        (orderDetails.is_revice_proforma == 'Y' &&
                          orderDetails.order_status == 'COMPLETED'))
                    "
                    class="upload-btn-container mb-4"
                  >
                    <img
                      :src="image ? image : 'static/images/default.png'"
                      width="100"
                      height="700"
                      alt="Uploaded Cheque Image"
                      style="width: 100% !important; height: 100% !important"
                    />
                    <div class="text-center" style="margin: 10px !important">
                      <button class="btn btn-primary" v-on:click="removeFile()">
                        Remove
                      </button>
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="
                      (orderDetails.order_status == 'WORK_START' ||
                        orderDetails.order_status == 'COMPLETED') &&
                      ((orderDetails.order_status != 'PENDING' &&
                        orderDetails.order_status != 'DELIVERED' &&
                        is_proforma == 'N') ||
                        (orderDetails.order_status == 'COMPLETED' &&
                          orderDetails.is_revice_proforma == 'Y'))
                    "
                  >
                    <div class="col-lg-5">
                      <h3>Material Cost</h3>
                    </div>
                    <div class="col-lg-7">
                      <input
                        :disabled="
                          (orderDetails.order_status == 'WORK_START' &&
                            orderDetails.is_revice_proforma == 'Y') ||
                          (orderDetails.order_status == 'COMPLETED' &&
                            orderDetails.is_revice_proforma == 'N')
                        "
                        type="text"
                        class="form-control mb-3"
                        placeholder="Enter amount incl GST"
                        id=""
                        v-if="orderDetails.estimate_type == 'IMAGE'"
                        v-model="orderDetails.material_cost_image"
                      />
                      <input
                        :disabled="
                          (orderDetails.order_status == 'WORK_START' &&
                            orderDetails.is_revice_proforma == 'Y') ||
                          (orderDetails.order_status == 'COMPLETED' &&
                            orderDetails.is_revice_proforma == 'N')
                        "
                        type="text"
                        class="form-control mb-3"
                        placeholder="Enter amount incl GST"
                        id=""
                        v-if="orderDetails.estimate_type == 'PDF'"
                        v-model="orderDetails.material_cost"
                      />
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="
                      (orderDetails.order_status == 'WORK_START' ||
                        orderDetails.order_status == 'COMPLETED') &&
                      ((orderDetails.order_status != 'PENDING' &&
                        orderDetails.order_status != 'DELIVERED' &&
                        is_proforma == 'N') ||
                        (orderDetails.order_status == 'COMPLETED' &&
                          orderDetails.is_revice_proforma == 'Y'))
                    "
                  >
                    <div class="col-lg-5">
                      <h3>Labour Cost</h3>
                    </div>
                    <div class="col-lg-7">
                      <input
                        :disabled="
                          (orderDetails.order_status == 'WORK_START' &&
                            orderDetails.is_revice_proforma == 'Y') ||
                          (orderDetails.order_status == 'COMPLETED' &&
                            orderDetails.is_revice_proforma == 'N')
                        "
                        type="text"
                        class="form-control mb-3"
                        placeholder="Enter amount incl GST"
                        id=""
                        v-if="orderDetails.estimate_type == 'IMAGE'"
                        v-model="orderDetails.labour_cost_image"
                      />
                      <input
                        :disabled="
                          (orderDetails.order_status == 'WORK_START' &&
                            orderDetails.is_revice_proforma == 'Y') ||
                          (orderDetails.order_status == 'COMPLETED' &&
                            orderDetails.is_revice_proforma == 'N')
                        "
                        type="text"
                        class="form-control mb-3"
                        placeholder="Enter amount incl GST"
                        id=""
                        v-if="orderDetails.estimate_type == 'PDF'"
                        v-model="orderDetails.labour_cost"
                      />
                    </div>
                  </div>
                  <button
                    v-if="orderDetails && orderDetails.order_status == 'WORK_START'"
                    class="btn btn-primary px-5 mt-3"
                    id="estimate-cost-payment6"
                    :disabled="!flow_file || !fileType || fileType == 'PDF'"
                    v-on:click="sendMaterialCost('PROFORMA', 'estimate-cost-payment6')"
                  >
                    Work Complete
                  </button>

                  <button
                    v-if="
                      orderDetails.is_material_labour_cost_added == 'Y' &&
                      orderDetails.is_revice_proforma == 'Y' &&
                      orderDetails.order_status == 'COMPLETED'
                    "
                    class="btn btn-primary px-5 mt-3"
                    id="estimate-cost-payment7"
                    :disabled="!flow_file || !fileType || fileType == 'PDF'"
                    v-on:click="sendMaterialCost('PROFORMA', 'estimate-cost-payment7')"
                  >
                    Send Revision
                  </button>
                </template>

                <div
                  class="m-3 text-center"
                  v-if="orderDetails.order_status == 'WORK_START'"
                >
                  <h3>Or</h3>
                </div>

                <template
                  v-if="
                    orderDetails.order_status == 'WORK_START' ||
                    orderDetails.estimate_type == 'PDF'
                  "
                >
                  <div
                    class="upload-btn-container mb-4"
                    v-if="
                      (!flow_file || (flow_file && fileType == 'IMAGE')) &&
                      (orderDetails.order_status == 'WORK_START' ||
                        orderDetails.order_status == 'COMPLETED') &&
                      ((orderDetails.order_status != 'DELIVERED' &&
                        orderDetails.order_status == 'WORK_START') ||
                        (orderDetails.is_revice_proforma == 'Y' &&
                          orderDetails.order_status == 'COMPLETED'))
                    "
                  >
                    <p
                      class="font-bold mb-2"
                      v-if="
                        orderDetails.order_status == 'WORK_START' ||
                        orderDetails.order_status == 'COMPLETED'
                      "
                    >
                      Upload Document
                    </p>
                    <p
                      style="float: right"
                      class="bg-warning badge font-medium font-12"
                      v-if="orderDetails.order_status == 'WORK_START'"
                    >
                      <span
                        v-if="
                          orderDetails.is_revice_proforma == 'N' &&
                          orderDetails.order_status == 'PROFORMA'
                        "
                        >Sent</span
                      >
                      <span
                        v-if="
                          orderDetails.is_revice_proforma == 'Y' &&
                          orderDetails.order_status == 'COMPLETED'
                        "
                        >Revision Requested</span
                      >
                      <span
                        v-if="
                          orderDetails.is_revice_proforma == 'N' &&
                          orderDetails.order_status == 'COMPLETED'
                        "
                        >Revision Sent</span
                      >
                    </p>

                    <input
                      type="file"
                      accept="application/pdf"
                      @change="uploadEstimatePdfDocument($event)"
                    />
                    <label for="preview-pan-0">
                      <div class="py-4 text-center">
                        <div class="mb-3">
                          <img
                            src="static/images/icon-file.svg"
                            width="20"
                            class="mx-auto"
                            alt=""
                          />
                        </div>
                        <div class="mb-3 text-secondary">Select document to upload</div>
                        <div>
                          <button class="btn btn-primary">Upload File</button>
                        </div>
                      </div>
                    </label>
                    <div class="text-center">
                      <span class="font-12 text-secondary"
                        >Supported files: PDF (Max. file size 4 MB)</span
                      >
                    </div>
                  </div>
                  <div
                    v-if="
                      flow_file &&
                      fileType &&
                      fileType == 'PDF' &&
                      (orderDetails.order_status == 'WORK_START' ||
                        orderDetails.order_status == 'COMPLETED') &&
                      ((orderDetails.order_status != 'DELIVERED' &&
                        orderDetails.order_status == 'WORK_START') ||
                        (orderDetails.is_revice_proforma == 'Y' &&
                          orderDetails.order_status == 'COMPLETED'))
                    "
                    class="upload-btn-container mb-4 text-center"
                  >
                    <img
                      src="/static/images/pdf.png"
                      height="700"
                      alt="Uploaded Cheque Image"
                      style="width: 15% !important"
                    />
                    <div class="text-center" style="margin: 10px !important">
                      <button class="btn btn-primary" v-on:click="removeFile()">
                        Remove
                      </button>
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="
                      (orderDetails.order_status == 'WORK_START' ||
                        orderDetails.order_status == 'COMPLETED') &&
                      ((orderDetails.order_status != 'PENDING' &&
                        orderDetails.order_status != 'DELIVERED' &&
                        is_proforma == 'N') ||
                        (orderDetails.order_status == 'COMPLETED' &&
                          orderDetails.is_revice_proforma == 'Y'))
                    "
                  >
                    <div class="col-lg-5">
                      <h3>Material Cost</h3>
                    </div>
                    <div class="col-lg-7">
                      <input
                        :disabled="
                          (orderDetails.order_status == 'WORK_START' &&
                            orderDetails.is_revice_proforma == 'Y') ||
                          (orderDetails.order_status == 'COMPLETED' &&
                            orderDetails.is_revice_proforma == 'N')
                        "
                        type="text"
                        class="form-control mb-3"
                        placeholder="Enter amount incl GST"
                        id=""
                        v-model="orderDetails.material_cost"
                      />
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="
                      (orderDetails.order_status == 'WORK_START' ||
                        orderDetails.order_status == 'COMPLETED') &&
                      ((orderDetails.order_status != 'PENDING' &&
                        orderDetails.order_status != 'DELIVERED' &&
                        is_proforma == 'N') ||
                        (orderDetails.order_status == 'COMPLETED' &&
                          orderDetails.is_revice_proforma == 'Y'))
                    "
                  >
                    <div class="col-lg-5">
                      <h3>Labour Cost</h3>
                    </div>
                    <div class="col-lg-7">
                      <input
                        :disabled="
                          (orderDetails.order_status == 'WORK_START' &&
                            orderDetails.is_revice_proforma == 'Y') ||
                          (orderDetails.order_status == 'COMPLETED' &&
                            orderDetails.is_revice_proforma == 'N')
                        "
                        type="text"
                        class="form-control mb-3"
                        placeholder="Enter amount incl GST"
                        id=""
                        v-model="orderDetails.labour_cost"
                      />
                    </div>
                  </div>
                  <button
                    v-if="orderDetails && orderDetails.order_status == 'WORK_START'"
                    class="btn btn-primary px-5 mt-3"
                    id="estimate-cost-payment8"
                    :disabled="!flow_file || !fileType || fileType == 'IMAGE'"
                    v-on:click="sendMaterialCost('PROFORMA', 'estimate-cost-payment8')"
                  >
                    Work Complete
                  </button>

                  <button
                    v-if="
                      orderDetails.is_material_labour_cost_added == 'Y' &&
                      orderDetails.is_revice_proforma == 'Y' &&
                      orderDetails.order_status == 'COMPLETED'
                    "
                    class="btn btn-primary px-5 mt-3"
                    id="estimate-cost-payment9"
                    :disabled="!flow_file || !fileType || fileType == 'IMAGE'"
                    v-on:click="sendMaterialCost('PROFORMA', 'estimate-cost-payment9')"
                  >
                    Send Revision
                  </button>
                </template>
              </div>
            </template>

            <div
              class="card p-4 mb-4"
              v-if="
                (orderDetails.order_status != 'DELIVERED' &&
                  orderDetails &&
                  orderDetails.order_status == 'WORK_START') ||
                orderDetails.order_status == 'COMPLETED'
              "
            >
              <div
                class="card p-4 form-style mb-3"
                v-if="orderDetails && orderDetails.order_status == 'WORK_START'"
              >
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <p class="font-18 font-bold m-0">Request Advance Payment (optional)</p>
                  <span
                    class="bg-warning badge font-medium font-12"
                    v-if="
                      orderDetails.advanced_amount && orderDetails.advanced_payment == 'N'
                    "
                    >Requested</span
                  >
                  <span
                    class="bg-success badge font-medium font-12"
                    v-if="
                      orderDetails.advanced_amount && orderDetails.advanced_payment == 'Y'
                    "
                    >Paid</span
                  >
                </div>
                <input
                  :disabled="orderDetails.advanced_amount"
                  type="text"
                  class="form-control mb-3"
                  placeholder="Enter amount incl GST"
                  id=""
                  v-model="amount"
                />
                <input
                  :disabled="orderDetails.advanced_amount"
                  type="text"
                  class="form-control"
                  placeholder="Remark"
                  id=""
                  v-model="remark"
                />
                <button
                  v-if="!orderDetails.advanced_amount"
                  :disabled="!this.amount"
                  class="btn btn-primary px-5 mt-3"
                  id="advanced-payment"
                  v-on:click="sendRequest"
                >
                  Send
                </button>
              </div>
              <div
                class="card p-4"
                v-if="orderDetails && orderDetails.order_status == 'COMPLETED'"
              >
                <p class="font-bold">Enter OTP</p>
                <div class="d-flex gap-3 mb-3">
                  <v-otp-input
                    ref="otpInput"
                    input-classes="otp-input form-control text-center"
                    separator="&nbsp;&nbsp;&nbsp;&nbsp;"
                    inputType="text"
                    :num-inputs="4"
                    v-model:value="otp"
                    :should-auto-focus="true"
                    :should-focus-order="true"
                  />
                </div>
                <button
                  :disabled="!this.otp || this.otp.length < 4"
                  class="btn btn-primary px-5 mb-2"
                  id="deliver"
                  v-on:click="sendToDeliver"
                >
                  Deliver
                </button>
                <p class="font-12 text-secondary mb-0">
                  Note: Get the OTP from the customer and Deliver
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card p-4">
              <p class="font-bold font-18">Order Status</p>
              <div class="status-steps-wizard">
                <div
                  class="step-item"
                  :class="item?.order_status_date ? 'completed' : ''"
                  v-for="item in orderDetails.progress"
                  :key="item"
                >
                  <div class="check-mark">
                    <svg
                      fill="#059805"
                      width="20"
                      height="20"
                      viewBox="0 0 256 256"
                      id="Flat"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M128,24A104,104,0,1,0,232,128,104.12041,104.12041,0,0,0,128,24Zm49.53125,85.78906-58.67187,56a8.02441,8.02441,0,0,1-11.0625,0l-29.32813-28a8.00675,8.00675,0,0,1,11.0625-11.57812l23.79687,22.72656,53.14063-50.72656a8.00675,8.00675,0,0,1,11.0625,11.57812Z"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <div class="step-content">
                    <div class="mb-2">
                      <p class="step-title">{{ item.title }}</p>
                      <p class="description">
                        {{ item.message }}
                      </p>
                      <p class="description">
                        {{
                          $helperService.getOrderDetailFormattedDate(
                            item?.order_status_date
                          )
                        }}
                      </p>
                      <span
                        class="badge text-success font-14 bg-success-subtle"
                        v-if="item.amount"
                        >Paid: ₹{{ item.amount }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal -->
          <div
            class="modal fade"
            id="CancelModal"
            tabindex="-1"
            aria-labelledby="CancelModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <p class="font-bold font-18">Cancel order</p>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="calncel-type"
                      id="equestedtocancel"
                      value="Customer requested to cancel"
                      v-model="cancelReason"
                    />
                    <label class="form-check-label" for="equestedtocancel">
                      Customer requested to cancel
                    </label>
                  </div>
                  <div class="form-check mb-4">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="calncel-type"
                      id="notResponding"
                      value="Customer is not responding to call"
                      v-model="cancelReason"
                    />
                    <label class="form-check-label" for="notResponding">
                      Customer is not responding to call
                    </label>
                  </div>

                  <button
                    id="cancel-order"
                    type="button"
                    class="btn btn-primary w-100"
                    data-bs-dismiss="modal"
                    v-on:click="cancelOrder"
                    :disabled="!this.cancelReason"
                  >
                    Cancel Order
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal -->
          <div
            class="modal fade"
            id="NoshowModal"
            tabindex="-1"
            aria-labelledby="NoshowModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <p class="font-bold font-18">No Show</p>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="oncalncel-type"
                      id="onequestedtocancel"
                      value="Customer did not Arrived"
                      v-model="noshowReason"
                    />
                    <label class="form-check-label" for="equestedtocancel">
                      Customer did not Arrived
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="oncalncel-type"
                      id="omnotResponding"
                      value="Customer requested to cancle"
                      v-model="noshowReason"
                    />
                    <label class="form-check-label" for="notResponding">
                      Customer requested to cancle
                    </label>
                  </div>
                  <div class="form-check mb-4">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="oncalncel-type"
                      id="onnotResponding"
                      value="Customer is not responding to call"
                      v-model="noshowReason"
                      checked
                    />
                    <label class="form-check-label" for="notResponding">
                      Customer is not responding to call
                    </label>
                  </div>

                  <button
                    type="button"
                    class="btn btn-primary w-100"
                    data-bs-dismiss="modal"
                    v-on:click="noshowOrder"
                    :disabled="!this.noshowReason"
                  >
                    Cancel Order
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Accept Alert -->
          <div
            class="modal fade"
            id="AcceptModal"
            tabindex="-1"
            aria-labelledby="AcceptModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered modal-sm">
              <div class="modal-content">
                <div class="modal-body text-center p-4">
                  <p class="font-medium font-16 mb-4">Are you sure you want to accept?</p>
                  <div class="d-flex gap-3">
                    <button
                      id="close-accept-popup"
                      type="button"
                      class="btn btn-outline-danger w-100"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      id="order-accept"
                      type="button"
                      class="btn btn-primary w-100"
                      v-on:click="orderAccept"
                    >
                      Accept
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!----------------------------- Reschedule Modal  ----------------------------------->

          <div
            class="offcanvas offcanvas-end size-md"
            tabindex="-1"
            id="RescheduleBlade"
            aria-labelledby="RescheduleBladeLabel"
          >
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="RescheduleBladeLabel">Propose Time</h5>
              <button
                id="close-model-popup"
                type="button"
                class="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div class="offcanvas-body reshcedule-btns">
              <div class="d-flex justify-content-between mb-3">
                <span class="font-bold font-18">Select Date</span>
              </div>
              <div
                class="btn-group check-btns flex-wrap gap-3 d-grid"
                style="grid-template-columns: auto auto auto"
                role="group"
              >
                <div
                  v-for="(item, index) in rescheduleDateList"
                  :key="index"
                  class="btn-group"
                >
                  <input
                    type="checkbox"
                    class="btn-check"
                    :id="'day1_' + index"
                    :name="'day1_' + index"
                    autocomplete="off"
                    :disabled="item.is_off == 'Y'"
                    v-on:change="storeDate(item.date, $event)"
                    :checked="isCheckedDate(item.date)"
                  />
                  <label class="btn btn-outline-primary" :for="'day1_' + index">
                    {{ $helperService.dateFormat(item?.date) }}
                  </label>
                </div>
              </div>
              <hr />
              <div class="d-flex justify-content-between mb-3">
                <span class="font-bold font-18">Select time slot</span>
              </div>
              <div
                class="btn-group check-btns flex-wrap gap-3 d-grid"
                style="grid-template-columns: auto auto auto"
                role="group"
              >
                <div
                  v-for="(item, index) in rescheduleTimeList"
                  :key="index"
                  class="btn-group"
                >
                  <input
                    type="checkbox"
                    class="btn-check"
                    :id="'time1' + index"
                    autocomplete="off"
                    v-on:change="storeTime(item.title, $event)"
                    :checked="isCheckedTime(item.title)"
                  />
                  <label class="btn btn-outline-primary" :for="'time1' + index">{{
                    item?.title
                  }}</label>
                </div>
              </div>
              <ErrorComponent @retry="getDetails()" ref="errorComponent1" />
            </div>
            <div class="offcanvas-footer p-3">
              <button
                class="btn btn-primary w-100"
                data-bs-toggle="modal"
                data-bs-target="#RescheduleModal"
                :disabled="!this.selectedDate && !this.selectedTime"
              >
                Propose New Time
              </button>
            </div>
          </div>

          <!-- Reschedule Alert -->
          <div
            class="modal fade"
            id="RescheduleModal"
            tabindex="-1"
            aria-labelledby="RescheduleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered modal-sm">
              <div class="modal-content">
                <div class="modal-body text-center p-4">
                  <p class="font-medium font-16">Propose New Time</p>
                  <p class="text-secondary mb-4">
                    Are you sure you want to propose new time?
                  </p>
                  <div class="d-flex gap-3">
                    <button
                      id="close-save-date-time-popup"
                      type="button"
                      class="btn btn-outline-danger w-100"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      id="save-reschedule-date-time"
                      type="button"
                      class="btn btn-primary w-100"
                      v-on:click="saveRescheduleDateAndTime"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ErrorComponent ref="errorComponent" />
  </div>

  <!-- View Doc Modal  -->
  <div
    class="modal fade"
    id="DocumentModal"
    data-bs-backdrop="static"
    tabindex="-1"
    aria-labelledby="DocumentModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="DocumentModalLabel">Document</h1>
          <button
            id="reuplod-close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body text-center">
          <img :src="job_card" class="mx-auto h-100" alt="" style="width: 400px" />
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn btn-primary" v-on:click="reupload()">
            Reupload
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent.vue";
import VOtpInput from "vue3-otp-input";
export default {
  name: "OrderDetail",
  components: {
    ErrorComponent,
    VOtpInput,
  },
  data() {
    return {
      orderDetails: {
        id: "",
        workshop: {},
        gallery: [],
        progress: [],
        order_status: "",
        order_flow: "",
      },
      estimate_cost: "",
      uuid: "",
      cancelReason: "",
      noshowReason: "",
      selectedDate: "",
      pickup_date: "",
      pickup_time: "",
      selectedTime: "",
      finalrescheduleTimeList: [],
      rescheduleTimeList: [],
      rescheduleDateList: [],
      workshop_id: localStorage.getItem("workshop_id"),
      otp: "",
      amount: "",
      remark: "",
      is_job_card: "N",
      job_card: "",
      is_estimate: "N",
      estimate: "",
      is_revice_estimate: "N",
      revice_estimate: "",
      estimate_status: "PENDING",
      revice_estimate_status: "PENDING",
      proforma_status: "PENDING",
      revice_proforma_status: "PENDING",
      is_proforma: "N",
      proforma: "",
      is_revice_proforma: "N",
      final_invoice: "",
      flow_file: "",
      material_cost_old_estimate: 0,
      labour_cost_old_estimate: 0,
      material_cost_new_estimate: 0,
      labour_cost_new_estimate: 0,
      material_cost_old_profoma: 0,
      labour_cost_old_profoma: 0,
      fileType: null,
      image: "",
    };
  },

  mounted() {
    this.uuid = this.$route.params.uuid;
    this.getDetails();
  },
  methods: {
    getOrderDetails() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: "/order/detail/" + this.uuid,
          _body: {},
          _hide_loader: true,
        })
        .then((res) => {
          if (res && res.info) {
            this.orderDetails = res.info;
            this.pickup_date = this.orderDetails?.pickup_date;
            this.pickup_time = this.orderDetails?.pickup_time;
            this.is_revice_proforma = this.orderDetails.is_revice_proforma;
            this.is_revice_estimate = this.orderDetails.is_revice_estimate;
            this.orderDetails.material_cost_image = this.orderDetails.material_cost;
            this.orderDetails.labour_cost_image = this.orderDetails.labour_cost;
            if (this.orderDetails.advanced_amount) {
              this.amount = this.orderDetails.advanced_amount;
              this.remark = this.orderDetails.reason;
            }
            for (var i = 0; i < this.orderDetails.gallery.length; i++) {
              if (this.orderDetails.gallery[i].type == "JOB_CARD") {
                this.is_job_card = "Y";
                this.job_card = this.orderDetails.gallery[i].image;
              }
              if (this.orderDetails.gallery[i].type == "GENERATE_ESTIMATE") {
                this.is_estimate = "Y";
                this.estimate = this.orderDetails.gallery[i].image;
                this.estimate_status = this.orderDetails.gallery[i].status;
                this.material_cost_old_estimate = this.orderDetails.gallery[
                  i
                ].material_cost;
                this.labour_cost_old_estimate = this.orderDetails.gallery[i].labour_cost;
              }
              if (this.orderDetails.gallery[i].type == "REVICE_ESTIMATE") {
                this.revice_estimate = this.orderDetails.gallery[i].image;
                this.revice_estimate_status = this.orderDetails.gallery[i].status;
                this.material_cost_new_estimate = this.orderDetails.gallery[
                  i
                ].material_cost;
                this.labour_cost_new_estimate = this.orderDetails.gallery[i].labour_cost;
              }

              if (this.orderDetails.gallery[i].type == "PROFORMA") {
                this.is_proforma = "Y";
                this.proforma = this.orderDetails.gallery[i].image;
                this.proforma_status = this.orderDetails.gallery[i].status;
                this.material_cost_old_profoma = this.orderDetails.gallery[
                  i
                ].material_cost;
                this.labour_cost_old_profoma = this.orderDetails.gallery[i].labour_cost;
              }

              if (this.orderDetails.gallery[i].type == "INVOICE") {
                this.final_invoice = this.orderDetails.gallery[i].image;
              }
            }
          } else {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setTitle("No Data Available");
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        })
        .catch((error) => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
            this.$refs.errorComponent.setTitle("");
          }
          console.error(error);
        });
    },
    reupload() {
      const closebutton = document.getElementById("job_card-upload");
      closebutton.click();
    },
    orderAccept() {
      var obj = {
        status: "ACCEPT",
      };
      if (this.uuid) {
        this.$api
          .putAPI({
            _action: "order/status/" + this.uuid,
            _buttonId: "order-accept",
            _body: obj,
          })
          .then((res) => {
            const closebutton = document.getElementById("close-accept-popup");
            closebutton.click();
            if (res) {
              this.$toast.open({
                message: res.message,
                type: "success",
                duration: 3000,
                position: "top-right",
              });
              this.getOrderDetails();
            }
          })
          .catch((error) => {
            console.error(error);
            this.$toast.open({
              message: "Error",
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          });
      }
    },
    cancelOrder() {
      var obj = {
        reason: this.cancelReason,
        status: "DECLINE",
      };
      if (this.uuid) {
        this.$api
          .putAPI({
            _action: "order/status/" + this.uuid,
            _buttonId: "cancel-order",
            _body: obj,
          })
          .then((res) => {
            if (res) {
              this.cancelReason = "";
              this.$toast.open({
                message: res.message,
                type: "success",
                duration: 3000,
                position: "top-right",
              });
              this.getOrderDetails();
            }
          })
          .catch((error) => {
            this.cancelReason = "";
            console.error(error);
            this.$toast.open({
              message: "Error",
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          });
      }
    },
    noshowOrder() {
      var obj = {
        reason: this.noshowReason,
        status: "NO_SHOW",
      };
      if (this.uuid) {
        this.$api
          .putAPI({
            _action: "order/status/" + this.uuid,
            _buttonId: "cancel-order",
            _body: obj,
          })
          .then((res) => {
            if (res) {
              this.noshowReason = "";
              this.$toast.open({
                message: res.message,
                type: "success",
                duration: 3000,
                position: "top-right",
              });
              this.$router.push("/orders");
            }
          })
          .catch((error) => {
            this.noshowReason = "";
            console.error(error);
            this.$toast.open({
              message: "Error",
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          });
      }
    },

    checkSlot() {
      var newArray = [];
      newArray = this.finalrescheduleTimeList;
      for (let i = 0; i < this.rescheduleDateList.length; i++) {
        if (this.compareDates(this.rescheduleDateList[i].date, this.pickup_date)) {
          this.selectedDate = this.rescheduleDateList[i].date;
          break;
        }
      }

      const isToday = this.checkToday(this.pickup_date);

      if (isToday) {
        const currentTime = new Date();
        const currentTimeString = currentTime.toTimeString().split(" ")[0];
        const rescheduleTimeList = newArray.filter(
          (slot) => slot.from_time > currentTimeString
        );

        newArray = rescheduleTimeList;
      }

      for (let i = 0; i < newArray.length; i++) {
        if (newArray[i].title === this.pickup_time) {
          this.selectedTime = newArray[i].title;
          break;
        }
      }

      this.rescheduleTimeList = newArray;
      if (this.rescheduleTimeList.length === 0 && this.rescheduleDateList.length === 0) {
        if (this.$refs.errorComponent1) {
          this.$refs.errorComponent1.setTitle("No Data Available");
        }
      }
    },
    getDetails() {
      this.selectedDate = "";
      this.selectedTime = "";
      this.finalrescheduleTimeList = [];
      this.rescheduleTimeList = [];
      this.rescheduleDateList = [];
      this.$api
        .getAPI({
          _action: "/workshop/detail/" + this.workshop_id,
          _body: {},
          _hide_loader: true,
        })
        .then((res) => {
          if (res?.info?.time_list?.length > 0) {
            this.rescheduleTimeList = res.info.time_list;
            this.finalrescheduleTimeList = res.info.time_list;
          }
          if (res?.info?.date_list?.length > 0) {
            this.rescheduleDateList = res.info.date_list;
          }

          if (this.uuid) {
            this.getOrderDetails();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    checkToday(dateString) {
      const currentISTDate = new Date().toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
      });
      const currentDate = new Date(currentISTDate).toISOString().split("T")[0];
      const providedDate = dateString.slice(0, 10);
      const isToday = currentDate === providedDate;

      return isToday;
    },

    compareDates(date1, date2) {
      const d1 = new Date(date1);
      const d2 = new Date(date2);
      return (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
      );
    },
    isCheckedDate(date) {
      if (this.selectedDate && date) {
        return this.compareDates(date, this.selectedDate);
      } else {
        false;
      }
    },
    storeDate(date, event) {
      const isChecked = event.target.checked;
      this.pickup_date = date;
      if (isChecked) {
        this.selectedDate = date;
      } else {
        this.selectedDate = "";
      }
      this.checkSlot();
    },
    isCheckedTime(time) {
      if (time && this.selectedTime) {
        return this.selectedTime === time;
      } else {
        return false;
      }
    },
    storeTime(time, event) {
      const isChecked = event.target.checked;
      if (isChecked) {
        this.selectedTime = time;
      } else {
        this.selectedTime = "";
      }
    },
    saveRescheduleDateAndTime() {
      let formateDate = "";
      if (this.selectedDate) {
        formateDate = this.$helperService.formatDateYYMMDD(this.selectedDate);
      } else {
        formateDate = "";
      }
      var obj = {
        status: "RESCHEDULED",
        pickup_time: this.selectedTime,
        pickup_date: formateDate,
      };
      if (this.uuid) {
        this.$api
          .putAPI({
            _action: "order/status/" + this.uuid,
            _buttonId: "save-reschedule-date-time",
            _body: obj,
          })
          .then((res) => {
            const closebutton1 = document.getElementById("close-save-date-time-popup");
            closebutton1.click();
            const closebutton2 = document.getElementById("close-model-popup");
            closebutton2.click();

            if (res) {
              this.$toast.open({
                message: res.message,
                type: "success",
                duration: 3000,
                position: "top-right",
              });
              this.selectedDate = "";
              this.selectedTime = "";
              this.getOrderDetails();
            }
          })
          .catch((error) => {
            this.selectedDate = "";
            this.selectedTime = "";
            console.error(error);
            this.$toast.open({
              message: "Error",
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          });
      }
    },
    sendToDeliver() {
      this.$api
        .putAPI({
          _action: "order/status/" + this.uuid,
          _buttonId: "deliver",
          _body: { status: "DELIVERED", otp: this.otp },
        })
        .then((res) => {
          if (res) {
            this.$toast.open({
              message: res.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.getOrderDetails();
          }
        })
        .catch(() => {
          this.$toast.open({
            message: "Error",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        });
    },
    sendRequest() {
      this.$api
        .postAPI({
          _action: "order/advance-payment/" + this.uuid,
          _buttonId: "advanced-payment",
          _body: { advanced_amount: this.amount, reason: this.remark },
        })
        .then((res) => {
          if (res) {
            this.$toast.open({
              message: res.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.getOrderDetails();
          }
        })
        .catch(() => {
          this.$toast.open({
            message: "Error",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        });
    },
    sendCost() {
      this.$api
        .putAPI({
          _action: "order/status/" + this.uuid,
          _buttonId: "estimate-payment",
          _body: { estimate_cost: this.orderDetails.estimate_cost, status: "SEND_COST" },
        })
        .then((res) => {
          if (res) {
            this.$toast.open({
              message: res.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.getOrderDetails();
          }
        })
        .catch(() => {
          this.$toast.open({
            message: "Error",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        });
    },
    uploadDocument(event) {
      const file = event.target.files[0];
      this.$api
        .uploadImageAPI({
          _action: "/order/gallery/" + this.uuid,
          _file: file,
          _key: "file",
          _body: {
            type: "JOB_CARD",
          },
        })
        .then((res) => {
          if (res) {
            this.$toast.open({
              message: res.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            if (document.getElementById("reuplod-close")) {
              const closebutton = document.getElementById("reuplod-close");
              closebutton.click();
            }
            this.getOrderDetails();
          }
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          this.$toast.open({
            message: "Error",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        });
    },

    sendMaterialCost(type, buttonId) {
      var obj = {
        type: this.fileType,
        doc_type: type,
        material_cost:
          this.fileType == "IMAGE"
            ? this.orderDetails.material_cost_image
            : this.orderDetails.material_cost,
        labour_cost:
          this.fileType == "IMAGE"
            ? this.orderDetails.labour_cost_image
            : this.orderDetails.labour_cost,
      };

      this.$api
        .uploadImageAPI({
          _action: "/estimate/" + this.uuid,
          _file: this.flow_file,
          _key: "file",
          _body: obj,
          _buttonId: buttonId,
        })
        .then((res) => {
          this.flow_file = null;
          if (res) {
            this.$toast.open({
              message: res.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            if (document.getElementById("reuplod-close")) {
              const closebutton = document.getElementById("reuplod-close");
              closebutton.click();
            }
            this.getOrderDetails();
          }
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          this.$toast.open({
            message: "Error",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        });
    },
    uploadEstimateDocument(event) {
      this.flow_file = event.target.files[0];

      if (this.flow_file) {
        const imageTypes = ["image/jpeg", "image/png", "image/gif"];

        if (imageTypes.includes(this.flow_file.type)) {
          this.fileType = "IMAGE";
          console.log("File type is IMAGE");
          const blob = URL.createObjectURL(this.flow_file);
          this.image = blob;
        } else {
          alert("Please upload only image files (JPEG, PNG, GIF) ");
          this.flow_file = null;
          this.fileType = null;
        }
      }
    },

    uploadEstimatePdfDocument(event) {
      this.flow_file = event.target.files[0];

      if (this.flow_file) {
        const pdfType = "application/pdf";

        if (this.flow_file.type === pdfType) {
          this.fileType = "PDF";
          console.log("File type is PDF");
          this.image = "";
        } else {
          alert("Please upload only PDF files.");
          this.flow_file = null;
          this.fileType = null;
        }
      }
    },

    startWork() {
      this.$api
        .putAPI({
          _action: "order/status/" + this.uuid,
          _buttonId: "work-start",
          _body: { status: "WORK_START" },
        })
        .then((res) => {
          if (res) {
            this.$toast.open({
              message: res.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.getOrderDetails();
          }
        })
        .catch(() => {
          this.$toast.open({
            message: "Error",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        });
    },

    downloadFile(file) {
      window.open(file);
    },

    removeFile() {
      this.flow_file = "";
    },
  },
};
</script>
